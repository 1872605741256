import React, { useState, useEffect } from 'react';
import { 
    CircularProgress, Typography, Box, Paper, Chip, Button,
    Grid, Divider, Card, CardContent, Alert
} from '@mui/material';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import PageHeader from './PageHeader';
import { 
    Assignment, ArrowBack, EventNote, LocationOn, Person, 
    Business, Warning, Timeline
} from '@mui/icons-material';
import StylishTable from './StylishTable';
import ActionButton from './ActionButton';

const ROPendingDetailsPage = () => {
    const { orderNumber } = useParams();
    const navigate = useNavigate();
    const [orderDetails, setOrderDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    
    const user = JSON.parse(localStorage.getItem('user'));
    const username = user ? user.email.split('@')[0] : null;

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                setLoading(true);
                if (!orderNumber) {
                    throw new Error('Brak numeru zamówienia.');
                }

                const response = await axios.get(`/api/ro-pending/${orderNumber}?username=${username}`);
                setOrderDetails(response.data);
            } catch (error) {
                console.error('Błąd podczas pobierania szczegółów zamówienia:', error);
                
                if (error.response && error.response.status === 403) {
                    setError('Nie masz dostępu do tego zamówienia.');
                } else {
                    setError('Nie udało się pobrać szczegółów zamówienia.');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchOrderDetails();
    }, [orderNumber, username]);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        
        try {
            const date = new Date(dateString);
            return date.toLocaleDateString('pl-PL', { 
                year: 'numeric', 
                month: '2-digit', 
                day: '2-digit' 
            });
        } catch (error) {
            return dateString;
        }
    };

    const calculateTotalValue = () => {
        if (!orderDetails || !orderDetails.products || !Array.isArray(orderDetails.products)) {
            return 0;
        }
        
        return orderDetails.products.reduce((sum, product) => sum + product.value, 0);
    };

    const calculateTotalRemainingQuantity = () => {
        if (!orderDetails || !orderDetails.products || !Array.isArray(orderDetails.products)) {
            return 0;
        }
        
        return orderDetails.products.reduce((sum, product) => sum + product.remainingQuantity, 0);
    };

    return (
        <Box sx={{ padding: '20px', maxWidth: '1200px', margin: 'auto' }}>
            <PageHeader 
                title={`Szczegóły zamówienia: ${orderNumber}`} 
                subtitle="Informacje o zamówieniu i produktach do realizacji"
                icon={<Assignment />}
            />

            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                    <CircularProgress />
                </Box>
            )}
            
            {error && (
                <Alert 
                    severity="error"
                    sx={{ mb: 3 }}
                    icon={<Warning />}
                >
                    {error}
                </Alert>
            )}

            {!loading && !error && orderDetails && (
                <>
                    {/* Informacje o zamówieniu - karty */}
                    <Grid container spacing={3} sx={{ mb: 4 }}>
                        {/* Karta klienta */}
                        <Grid item xs={12} md={4}>
                            <Card sx={{ height: '100%', borderRadius: '16px' }}>
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Business sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="h6">Dane klienta</Typography>
                                    </Box>
                                    <Divider sx={{ mb: 2 }} />
                                    <Typography sx={{ mb: 1 }}>
                                        <strong>Nazwa:</strong> {orderDetails.clientName}
                                    </Typography>
                                    <Typography sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                                        <LocationOn fontSize="small" sx={{ mr: 0.5, color: 'action.active' }} />
                                        <strong>Miasto:</strong> {orderDetails.city}
                                    </Typography>
                                    <Typography>
                                        <strong>Województwo:</strong> {orderDetails.region}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Karta zamówienia */}
                        <Grid item xs={12} md={4}>
                            <Card sx={{ height: '100%', borderRadius: '16px' }}>
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <EventNote sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="h6">Informacje o zamówieniu</Typography>
                                    </Box>
                                    <Divider sx={{ mb: 2 }} />
                                    <Typography sx={{ mb: 1 }}>
                                        <strong>Numer zamówienia:</strong> {orderDetails.orderNumber}
                                    </Typography>
                                    <Typography sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                                        <Timeline fontSize="small" sx={{ mr: 0.5, color: 'action.active' }} />
                                        <strong>Data:</strong> {formatDate(orderDetails.date)}
                                    </Typography>
                                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Person fontSize="small" sx={{ mr: 0.5, color: 'action.active' }} />
                                        <strong>Handlowiec:</strong> {orderDetails.salesRep}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Karta podsumowania */}
                        <Grid item xs={12} md={4}>
                            <Card sx={{ height: '100%', borderRadius: '16px', bgcolor: 'primary.light', color: 'primary.contrastText' }}>
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Assignment sx={{ mr: 1 }} />
                                        <Typography variant="h6">Podsumowanie</Typography>
                                    </Box>
                                    <Divider sx={{ mb: 2, borderColor: 'primary.contrastText' }} />
                                    <Typography variant="h5" sx={{ mb: 1 }}>
                                        {calculateTotalValue().toLocaleString('pl-PL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} zł
                                    </Typography>
                                    <Typography sx={{ mb: 1 }}>
                                        <strong>Liczba produktów:</strong> {orderDetails.products.length}
                                    </Typography>
                                    <Typography sx={{ mb: 1 }}>
                                        <strong>Ilość do realizacji:</strong> {calculateTotalRemainingQuantity()}
                                    </Typography>
                                    <Box sx={{ mt: 2 }}>
                                        <Chip 
                                            label="Do realizacji" 
                                            color="warning" 
                                            sx={{ fontWeight: 'bold' }}
                                        />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    {/* Lista produktów */}
                    <Paper sx={{ p: 3, borderRadius: '16px', mb: 3 }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Produkty do realizacji
                        </Typography>
                        <StylishTable
                            columns={[
                                { id: 'productName', label: 'Nazwa produktu' },
                                { id: 'brand', label: 'Marka' },
                                { id: 'producer', label: 'Producent' },
                                { id: 'quantity', label: 'Zamówiono', align: 'right', format: (value) => value.toLocaleString('pl-PL') },
                                { id: 'remainingQuantity', label: 'Do realizacji', align: 'right', 
                                  format: (value) => (
                                    <Chip 
                                        label={value.toLocaleString('pl-PL')}
                                        color={value > 0 ? "warning" : "success"}
                                        size="small"
                                    />
                                  )
                                },
                                { id: 'value', label: 'Wartość', align: 'right', format: (value) => 
                                    `${value.toLocaleString('pl-PL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} zł`
                                },
                            ]}
                            data={orderDetails.products}
                            summary={{
                                productName: 'Podsumowanie',
                                quantity: orderDetails.products.reduce((sum, product) => sum + product.quantity, 0),
                                remainingQuantity: orderDetails.products.reduce((sum, product) => sum + product.remainingQuantity, 0),
                                value: calculateTotalValue()
                            }}
                        />
                    </Paper>

                    {/* Przyciski akcji */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                        <ActionButton
                            variant="outlined"
                            onClick={() => navigate('/ro-pending')}
                            startIcon={<ArrowBack />}
                            color="inherit"
                        >
                            Powrót do listy
                        </ActionButton>
                        
                        {username === 'admin' || username === 'marcin.krukowski' ? (
                            <ActionButton
                                variant="contained"
                                onClick={() => navigate('/ro-przyporzadkowanie')}
                                color="primary"
                            >
                                Zarządzaj przyporządkowaniem
                            </ActionButton>
                        ) : null}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ROPendingDetailsPage;