import React from 'react';
import { Button, Box, Tooltip } from '@mui/material';

const ActionButton = ({ icon, label, onClick, color = 'primary', variant = 'contained', tooltip, ...props }) => {
  const buttonContent = (
    <Button
      variant={variant}
      color={color}
      startIcon={icon}
      onClick={onClick}
      sx={{
        borderRadius: '12px',
        padding: '12px 24px',
        transition: 'all 0.3s',
        fontWeight: 600,
        boxShadow: variant === 'contained' 
          ? '0 4px 12px rgba(0,0,0,0.15)' 
          : 'none',
        background: variant === 'contained' && color === 'primary' 
          ? 'linear-gradient(45deg, #1976d2, #42a5f5)' 
          : undefined,
        '&:hover': {
          transform: 'translateY(-3px)',
          boxShadow: variant === 'contained' 
            ? '0 8px 16px rgba(0,0,0,0.2)' 
            : 'none',
          background: variant === 'contained' && color === 'primary' 
            ? 'linear-gradient(45deg, #1565c0, #1976d2)' 
            : undefined,
        },
        '&:active': {
          transform: 'translateY(-1px)',
          boxShadow: variant === 'contained'
            ? '0 4px 8px rgba(0,0,0,0.1)'
            : 'none',
        },
        ...props.sx
      }}
      {...props}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        position: 'relative',
        '&::after': variant === 'contained' ? {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)',
          transform: 'translateX(-100%)',
          transition: 'transform 0.6s ease',
        } : {},
        '&:hover::after': variant === 'contained' ? {
          transform: 'translateX(100%)',
        } : {},
      }}>
        {label}
      </Box>
    </Button>
  );

  return tooltip ? (
    <Tooltip title={tooltip} placement="top" arrow>
      {buttonContent}
    </Tooltip>
  ) : buttonContent;
};

export default ActionButton;