import React from 'react';
import { Box, Typography, Paper, Avatar, Breadcrumbs, Link, useMediaQuery, Tooltip } from '@mui/material';
import { Home, NavigateNext, Info } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

const PageHeader = ({ title, subtitle, icon, breadcrumbs = [] }) => {
  const location = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));
  
  // Trymuj tekst jeśli jest zbyt długi na mobilnym urządzeniu
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  // Automatycznie generuj ścieżkę nawigacji jeśli nie została dostarczona
  const generateBreadcrumbs = () => {
    if (breadcrumbs.length > 0) return breadcrumbs;
    
    const path = location.pathname;
    const segments = path.split('/').filter(s => s);
    
    if (segments.length === 0) return [];
    
    const result = [{ label: 'Home', path: '/', icon: <Home fontSize="small" /> }];
    
    let currentPath = '';
    segments.forEach((segment, index) => {
      currentPath += `/${segment}`;
      // Formatuj nazwę (zamień myślniki na spacje i kapitalizuj pierwsze litery)
      const formattedName = segment
        .replace(/-/g, ' ')
        .replace(/\b\w/g, l => l.toUpperCase());
      
      result.push({
        label: index === segments.length - 1 ? title : formattedName,
        path: currentPath
      });
    });
    
    return result;
  };
  
  const finalBreadcrumbs = generateBreadcrumbs();
  const displayedTitle = isMobile ? truncateText(title, 30) : title;
  const displayedSubtitle = isMobile ? truncateText(subtitle, 60) : subtitle;

  return (
    <Paper 
      elevation={0}
      sx={{ 
        position: 'relative',
        overflow: 'hidden',
        padding: isMobile ? '20px' : isTablet ? '24px 30px' : '32px 40px',
        borderRadius: { xs: '16px', sm: '20px', md: '24px' },
        marginBottom: isMobile ? '20px' : '30px',
        background: (theme) => theme.palette.mode === 'dark'
          ? 'linear-gradient(135deg, #1A2035 0%, #1E1E2D 100%)'
          : 'linear-gradient(135deg, #E3F2FD 0%, #BBDEFB 100%)',
        boxShadow: (theme) => theme.palette.mode === 'dark'
          ? '0 15px 35px rgba(0,0,0,0.2)'
          : '0 15px 35px rgba(3,169,244,0.1)',
      }}
    >
      {/* Breadcrumbs navigation - ukryte na mobilnej wersji */}
      {!isMobile && finalBreadcrumbs.length > 0 && (
        <Breadcrumbs 
          separator={<NavigateNext fontSize="small" />} 
          sx={{ mb: 2, position: 'relative', zIndex: 1 }}
          maxItems={isTablet ? 2 : 4}
          itemsAfterCollapse={isTablet ? 1 : 2}
          itemsBeforeCollapse={isTablet ? 1 : 2}
        >
          {finalBreadcrumbs.map((crumb, index) => (
            <Link
              key={index}
              color={index === finalBreadcrumbs.length - 1 ? 'textPrimary' : 'inherit'}
              href={crumb.path}
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                textDecoration: 'none',
                fontSize: isTablet ? '0.75rem' : '0.875rem',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
              underline="none"
            >
              {crumb.icon && (
                <Box component="span" sx={{ mr: 0.5, display: 'flex', alignItems: 'center' }}>
                  {crumb.icon}
                </Box>
              )}
              <Typography variant="body2" color="inherit" fontWeight={index === finalBreadcrumbs.length - 1 ? 'medium' : 'normal'}>
                {isTablet && crumb.label.length > 15 ? truncateText(crumb.label, 15) : crumb.label}
              </Typography>
            </Link>
          ))}
        </Breadcrumbs>
      )}

      {/* Main header section */}
      <Box 
        sx={{ 
          display: 'flex',
          alignItems: 'center',
          gap: isMobile ? '12px' : '24px',
          position: 'relative',
          zIndex: 1
        }}
      >
        {icon && (
          <Avatar
            sx={{
              width: isMobile ? 45 : isTablet ? 55 : 65,
              height: isMobile ? 45 : isTablet ? 55 : 65,
              backgroundColor: (theme) => theme.palette.mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.15)' 
                : 'rgba(33, 150, 243, 0.15)',
              boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
              color: (theme) => theme.palette.mode === 'dark' 
                ? '#90CAF9' 
                : '#2196F3'
            }}
          >
            {React.cloneElement(icon, { sx: { fontSize: isMobile ? 24 : 32 } })}
          </Avatar>
        )}
        
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography 
              variant={isMobile ? "h5" : isTablet ? "h5" : "h4"} 
              fontWeight={600} 
              gutterBottom={!!subtitle}
              sx={{
                color: (theme) => theme.palette.mode === 'dark' 
                  ? '#fff' 
                  : '#0D47A1',
                textShadow: (theme) => theme.palette.mode === 'dark'
                  ? '0 2px 4px rgba(0,0,0,0.3)'
                  : 'none'
              }}
            >
              {displayedTitle}
            </Typography>
            
            {/* Ikona informacyjna dla mobilnej wersji jeśli tytuł jest przycięty */}
            {isMobile && title !== displayedTitle && (
              <Tooltip title={title}>
                <Info fontSize="small" sx={{ opacity: 0.7 }} />
              </Tooltip>
            )}
          </Box>
          
          {subtitle && (
            <Typography 
              variant={isMobile ? "body2" : "body1"} 
              sx={{ 
                opacity: 0.9,
                color: (theme) => theme.palette.mode === 'dark' 
                  ? 'rgba(255,255,255,0.7)' 
                  : 'rgba(0,0,0,0.7)',
                maxWidth: '100%',
              }}
            >
              {displayedSubtitle}
              {isMobile && subtitle !== displayedSubtitle && (
                <Tooltip title={subtitle}>
                  <Info fontSize="small" sx={{ ml: 0.5, opacity: 0.7, verticalAlign: 'middle' }} />
                </Tooltip>
              )}
            </Typography>
          )}
        </Box>
      </Box>

      {/* Decorative Elements - ograniczone na mobilnych urządzeniach */}
      {!isMobile && (
        <>
          <Box 
            sx={{ 
              position: 'absolute',
              right: -20,
              bottom: -20,
              width: isTablet ? 80 : 100,
              height: isTablet ? 80 : 100,
              borderRadius: '50%',
              background: 'linear-gradient(135deg, rgba(33, 150, 243, 0.2) 0%, rgba(33, 150, 243, 0) 70%)',
              zIndex: 0
            }} 
          />
          <Box 
            sx={{ 
              position: 'absolute',
              right: 60,
              top: -10,
              width: isTablet ? 30 : 40,
              height: isTablet ? 30 : 40,
              borderRadius: '50%',
              background: 'linear-gradient(135deg, rgba(33, 150, 243, 0.1) 0%, rgba(33, 150, 243, 0) 70%)',
              zIndex: 0
            }} 
          />
        </>
      )}
    </Paper>
  );
};

export default PageHeader;