import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Konfiguracja Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBsUyS0z2c7YfBU0_LJ31kIT5Q14S5Ax2I",
  authDomain: "rozliczanie-wydatkow-ec6d1.firebaseapp.com",
  projectId: "rozliczanie-wydatkow-ec6d1",
  storageBucket: "rozliczanie-wydatkow-ec6d1.appspot.com",
  messagingSenderId: "403670123747",
  appId: "1:403670123747:web:6dd26ce3a33b53363db1d6",
  measurementId: "G-R9QV0XGWEB"
};

// Inicjalizacja Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);