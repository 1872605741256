import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
    CircularProgress, Typography, Button, Modal, Box, Paper, Alert
} from '@mui/material';
import axios from 'axios';
import StylishTable from './StylishTable';
import { ArrowBack, Warning } from '@mui/icons-material';

// Helper function for parsing discount values
function parseDiscount(value) {
    if (!value) return 0;
    // Always handle as string first to ensure consistent treatment
    const normalized = String(value).trim().replace(',', '.');
    const parsed = parseFloat(normalized);
    return isNaN(parsed) ? 0 : parsed;
}

const InvoicePage = () => {
    const { username, clientId, invoiceNumber } = useParams();
    const navigate = useNavigate();
    const formattedInvoiceNumber = invoiceNumber ? invoiceNumber.replace(/-/g, "/").trim() : "";
    const formattedClientName = clientId ? decodeURIComponent(clientId).trim() : "";

    const [invoiceDetails, setInvoiceDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openImage, setOpenImage] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    
    useEffect(() => {
        const fetchInvoiceDetails = async () => {
            try {
                // Properly decode parameters for display
                const decodedClientName = decodeURIComponent(formattedClientName);
                
                console.log("📥 Pobieranie faktury:", {
                    username,
                    clientName: formattedClientName,
                    invoiceNumber: formattedInvoiceNumber,
                    endpoint: `/api/invoice/${username}/${encodeURIComponent(formattedClientName)}/${encodeURIComponent(formattedInvoiceNumber)}`
                });

                const response = await axios.get(
                    `/api/invoice/${username}/${encodeURIComponent(formattedClientName)}/${encodeURIComponent(formattedInvoiceNumber)}`
                );

                if (response.data && response.data.products && Array.isArray(response.data.products)) {
                    // Make sure the data is sorted and properly formatted
                    const formattedProducts = response.data.products.map(product => ({
                        ...product,
                        // Ensure proper number formatting
                        quantity: parseInt(product.quantity, 10) || 0,
                        value: parseFloat(String(product.value).replace(',', '.')) || 0.0,
                        // Improved discount parsing using our helper function
                        discount: parseDiscount(product.discount)
                    })).sort((a, b) => a.productName.localeCompare(b.productName));
                    
                    console.log("✅ Pobrano produkty z faktury:", formattedProducts.length);
                    setInvoiceDetails(formattedProducts);
                } else {
                    console.error("❌ Nieprawidłowa struktura danych faktury:", response.data);
                    setError('Brak produktów na tej fakturze lub nieprawidłowy format danych.');
                }
            } catch (error) {
                console.error('❌ Błąd pobierania danych faktury:', error);
                
                // Enhanced error handling with more informative messages
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.error('Status błędu:', error.response.status);
                    console.error('Dane błędu:', error.response.data);
                    
                    if (error.response.status === 404) {
                        setError('Nie znaleziono faktury. Sprawdź poprawność numeru faktury i nazwy klienta.');
                    } else if (error.response.status === 500) {
                        setError('Wystąpił błąd serwera podczas pobierania danych faktury. Spróbuj ponownie później.');
                    } else {
                        setError(`Błąd podczas pobierania danych faktury: ${error.response.data.message || 'Nieznany błąd'}`);
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('Brak odpowiedzi z serwera:', error.request);
                    setError('Brak odpowiedzi z serwera. Sprawdź połączenie z internetem.');
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Błąd konfiguracji żądania:', error.message);
                    setError(`Błąd podczas konfiguracji żądania: ${error.message}`);
                }
            } finally {
                setLoading(false);
            }
        };
        
        fetchInvoiceDetails();
    }, [username, formattedClientName, formattedInvoiceNumber]);

    const handleImageOpen = (imageUrl) => {
        if (imageUrl) {
            setImageSrc(imageUrl);
            setOpenImage(true);
        }
    };
    
    const handleImageClose = () => {
        setOpenImage(false);
    };
    
    // Obliczanie podsumowania
    const totalQuantity = invoiceDetails.reduce((sum, item) => sum + item.quantity, 0);
    const totalValue = invoiceDetails.reduce((sum, item) => sum + item.value, 0);
    
    // Poprawione obliczanie średniego rabatu
    const validDiscounts = invoiceDetails.filter(item => item.discount > 0);
    const totalDiscounts = validDiscounts.reduce((sum, item) => sum + item.discount, 0);
    const averageDiscount = validDiscounts.length > 0 
        ? (totalDiscounts / validDiscounts.length).toFixed(2) 
        : "0.00";

    return (
        <div style={{ padding: '20px', maxWidth: '1000px', margin: 'auto' }}>
            <Typography variant="h4" gutterBottom>
                Faktura: {formattedInvoiceNumber}
            </Typography>
            <Typography variant="subtitle1" gutterBottom color="text.secondary">
                Klient: {decodeURIComponent(clientId)}
            </Typography>

            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', m: 4 }}>
                    <CircularProgress />
                </Box>
            )}
            
            {error && (
                <Alert 
                    severity="error" 
                    sx={{ mb: 3 }}
                    icon={<Warning />}
                >
                    {error}
                </Alert>
            )}

            {!loading && !error && invoiceDetails.length > 0 && (
                <>
                    <Paper sx={{ p: 3, mb: 3, borderRadius: '12px' }}>
                        <Typography variant="h6" gutterBottom>
                            Podsumowanie faktury
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                            <Box>
                                <Typography variant="body2" color="text.secondary">
                                    Liczba produktów:
                                </Typography>
                                <Typography variant="body1" fontWeight="bold">
                                    {invoiceDetails.length}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2" color="text.secondary">
                                    Łączna ilość:
                                </Typography>
                                <Typography variant="body1" fontWeight="bold">
                                    {totalQuantity}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2" color="text.secondary">
                                    Wartość całkowita:
                                </Typography>
                                <Typography variant="body1" fontWeight="bold">
                                    {totalValue.toLocaleString('pl-PL', { minimumFractionDigits: 2 })} zł
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2" color="text.secondary">
                                    Średni rabat:
                                </Typography>
                                <Typography variant="body1" fontWeight="bold">
                                    {averageDiscount}%
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>

                    <StylishTable
                        columns={[
                            { id: 'productName', label: 'Produkt', format: (value, row) => (
                                <div style={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    cursor: row.imageUrl ? 'pointer' : 'default',
                                }}>
                                    <span 
                                        style={{ 
                                            color: row.imageUrl ? '#1976d2' : 'inherit', 
                                            fontWeight: 'bold', 
                                            textDecoration: row.imageUrl ? 'underline' : 'none',
                                            marginRight: '4px'
                                        }}
                                        onClick={() => row.imageUrl && handleImageOpen(row.imageUrl)}
                                    >
                                        {value}
                                    </span>
                                    {row.imageUrl && (
                                        <span style={{ fontSize: '0.75rem', color: '#666', fontStyle: 'italic' }}>
                                            (kliknij, aby zobaczyć)
                                        </span>
                                    )}
                                </div>
                            )},
                            { id: 'quantity', label: 'Ilość', align: 'right' },
                            { id: 'value', label: 'Wartość sprzedaży (zł)', align: 'right', format: (value) => 
                                `${value.toLocaleString('pl-PL', { minimumFractionDigits: 2 })} zł` 
                            },
                            { id: 'discount', label: 'Rabat (%)', align: 'right', format: (value) => `${value}%` },
                        ]}
                        data={invoiceDetails}
                        summary={{
                            productName: 'Podsumowanie',
                            quantity: totalQuantity,
                            value: totalValue,
                            discount: `${averageDiscount}%`
                        }}
                    />

                    {/* 🔙 Przycisk powrotu */}
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={() => navigate(`/client-profile/${username}/${clientId}`)}
                            style={{ fontSize: '16px', padding: '10px 20px' }}
                            startIcon={<ArrowBack />}
                        >
                            Wróć do podsumowania klienta
                        </Button>
                    </div>
                    
                    {/* 🔹 MODAL z obrazkiem produktu */}
                    <Modal 
                        open={openImage && !!imageSrc} 
                        onClose={handleImageClose}
                        aria-labelledby="image-modal-title"
                    >
                        <Box sx={{ 
                            position: 'absolute', 
                            top: '50%', 
                            left: '50%', 
                            transform: 'translate(-50%, -50%)', 
                            bgcolor: 'background.paper', 
                            boxShadow: 24, 
                            p: 4,
                            borderRadius: 2,
                            maxWidth: '90%',
                            maxHeight: '90%',
                            overflow: 'auto'
                        }}>
                            <Typography id="image-modal-title" variant="h6" component="h2" gutterBottom>
                                Zdjęcie produktu
                            </Typography>
                            {imageSrc ? (
                                <img 
                                    src={imageSrc} 
                                    alt="Zdjęcie produktu" 
                                    style={{ 
                                        maxWidth: '100%', 
                                        maxHeight: '70vh',
                                        display: 'block',
                                        margin: '0 auto'
                                    }} 
                                />
                            ) : (
                                <Typography>Brak dostępnego zdjęcia</Typography>
                            )}
                            <Box sx={{ mt: 2, textAlign: 'right' }}>
                                <Button onClick={handleImageClose}>Zamknij</Button>
                            </Box>
                        </Box>
                    </Modal>
                </>
            )}
            
            {!loading && !error && invoiceDetails.length === 0 && (
                <Box
                    sx={{
                        textAlign: 'center',
                        p: 4,
                        my: 4,
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 1
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Brak produktów na tej fakturze
                    </Typography>
                    <Typography color="text.secondary" paragraph>
                        Nie znaleziono żadnych produktów przypisanych do faktury {formattedInvoiceNumber}.
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<ArrowBack />}
                        onClick={() => navigate(`/client-profile/${username}/${clientId}`)}
                        sx={{ mt: 2 }}
                    >
                        Wróć do podsumowania klienta
                    </Button>
                </Box>
            )}
        </div>
    );
};

export default InvoicePage;