import React, { useState } from 'react';
import { Box, List, ListItem, Button, FormControlLabel, Switch, Typography, Drawer, IconButton, Avatar } from '@mui/material';
import { Menu as MenuIcon, ChevronLeft, ReceiptOutlined, HistoryOutlined, BarChartOutlined, ShoppingCartOutlined, LogoutOutlined, Brightness4, Brightness7, CompareArrows, AssignmentOutlined } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';

const NavigationMenu = ({ darkMode, setDarkMode, onLogout, userName }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        onLogout();
      })
      .catch((error) => console.error('Błąd podczas wylogowania:', error));
  };

  const menuItems = [
    { label: 'Formularz Zwrotu', path: '/form', icon: <ReceiptOutlined />, color: '#1976d2' },
    { label: 'Historia', path: '/history', icon: <HistoryOutlined />, color: '#1976d2' },
    { label: 'ANALIZA FS', path: '/client-data', icon: <BarChartOutlined />, color: '#4caf50' },
    { label: 'ANALIZA RO', path: '/order-data', icon: <ShoppingCartOutlined />, color: '#ff9800' },
    { label: 'PORÓWNANIE FS/RO', path: '/integrated-data', icon: <CompareArrows />, color: '#9c27b0' },
    { label: 'RO do realizacji', path: '/ro-pending', icon: <AssignmentOutlined />, color: '#f44336' },
  ];

  const MenuItem = ({ item }) => {
    const isActive = location.pathname === item.path;
    return (
      <ListItem disablePadding sx={{ mb: 1 }}>
        <Button
          component={Link}
          to={item.path}
          fullWidth
          startIcon={item.icon}
          sx={{
            backgroundColor: isActive ? `${item.color}` : 'transparent',
            color: isActive ? '#ffffff' : item.color,
            fontWeight: 'bold',
            borderRadius: '12px',
            padding: '12px',
            justifyContent: 'flex-start',
            boxShadow: isActive ? '0 4px 10px rgba(0,0,0,0.2)' : 'none',
            transition: 'all 0.3s ease',
            position: 'relative',
            overflow: 'hidden',
            '&:hover': {
              backgroundColor: isActive ? item.color : `${item.color}20`,
              transform: 'translateY(-2px)',
              boxShadow: isActive ? '0 6px 12px rgba(0,0,0,0.25)' : '0 4px 8px rgba(0,0,0,0.15)',
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'linear-gradient(to right, transparent, rgba(255,255,255,0.2), transparent)',
              transform: 'translateX(-100%)',
              transition: 'transform 0.6s ease',
            },
            '&:hover::after': {
              transform: 'translateX(100%)',
            }
          }}
        >
          {item.label}
        </Button>
      </ListItem>
    );
  };

  const menuContent = (
    <>
      <Box sx={{ 
        padding: '20px',
        display: 'flex', 
        flexDirection: 'column',
        height: '100%'
      }}>
        {/* Logo i Avatar użytkownika */}
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center', 
          mb: 4 
        }}>
          <div>
            <Avatar 
              alt={userName} 
              src="/optimexlogo.png"
              sx={{ width: 80, height: 80, mb: 2, boxShadow: '0 4px 12px rgba(0,0,0,0.15)' }} 
            />
          </div>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            {userName}
          </Typography>
        </Box>

        {/* Menu Items */}
        <List sx={{ width: '100%' }}>
          {menuItems.map((item, index) => (
            <div key={item.path}>
              <MenuItem item={item} />
            </div>
          ))}
        </List>

        {/* Footer controls */}
        <Box sx={{ mt: 'auto', width: '100%' }}>
          <FormControlLabel
            control={
              <Switch
                checked={darkMode}
                onChange={() => setDarkMode((prev) => !prev)}
                color="primary"
              />
            }
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {darkMode ? <Brightness7 sx={{ mr: 1 }} /> : <Brightness4 sx={{ mr: 1 }} />}
                {darkMode ? 'Tryb Jasny' : 'Tryb Ciemny'}
              </Box>
            }
            sx={{ mb: 2 }}
          />
          <Button
            fullWidth
            variant="outlined"
            onClick={handleLogout}
            startIcon={<LogoutOutlined />}
            sx={{
              borderRadius: '12px',
              color: '#d32f2f',
              borderColor: '#d32f2f',
              padding: '12px',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: '#d32f2f',
                color: '#fff',
                boxShadow: '0 4px 10px rgba(211, 47, 47, 0.3)',
              },
            }}
          >
            Wyloguj się
          </Button>
        </Box>
      </Box>
    </>
  );

  return (
    <>
      {/* Mobile view with drawer */}
      <Box
        component="nav"
        sx={{
          display: { xs: 'block', md: 'none' },
          width: '100%',
          padding: '10px',
          backgroundColor: (theme) => theme.palette.background.paper,
          zIndex: 1000,
          boxShadow: (theme) => theme.shadows[3],
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => setOpen(true)} edge="start">
            <MenuIcon />
          </IconButton>
          <img
            src="/optimexlogo.png"
            alt="Optimex Logo"
            style={{ width: 120, height: 'auto', margin: '0 auto' }}
          />
        </Box>
        <Drawer
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
        >
          <Box sx={{ width: 280 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
              <IconButton onClick={() => setOpen(false)}>
                <ChevronLeft />
              </IconButton>
            </Box>
            {menuContent}
          </Box>
        </Drawer>
      </Box>

      {/* Desktop view */}
      <Box
        component="nav"
        sx={{
          width: 280,
          padding: '20px',
          backgroundColor: (theme) => theme.palette.background.paper,
          boxShadow: (theme) => theme.shadows[3],
          display: { xs: 'none', md: 'block' },
          height: '100vh',
          position: 'sticky',
          top: 0,
          overflow: 'auto',
        }}
      >
        {menuContent}
      </Box>
    </>
  );
};

export default NavigationMenu;