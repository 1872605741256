import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Typography, Table, TableBody, TableCell, 
    TableContainer, TableHead, TableRow, Paper, List, ListItem, Button 
} from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ClientOrderProfile = () => {
    const { username, clientId } = useParams();
    const [orderHistory, setOrderHistory] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchOrderHistory = async () => {
            try {
                const response = await axios.get(`/api/order-history/${username}/${encodeURIComponent(clientId)}`);
                setOrderHistory(response.data);
            } catch (error) {
                console.error("❌ Błąd pobierania historii zamówień:", error);
                setError('Nie udało się pobrać historii zamówień.');
            } finally {
                setLoading(false);
            }
        };

        fetchOrderHistory();
    }, [username, clientId]);

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <div style={{ padding: '20px', maxWidth: '1200px', margin: 'auto' }}>
            <Typography variant="h4" gutterBottom>Historia Zamówień: {decodeURIComponent(clientId)}</Typography>

            {/* 📊 Podsumowanie zamówień */}
            <Paper style={{ padding: '20px', marginBottom: '20px' }}>
                <Typography variant="h6">📊 Podsumowanie</Typography>
                <Typography>💰 Łączna wartość zamówień: <strong>{orderHistory.totalValue} PLN</strong></Typography>
                <Typography>🎯 Średni rabat: <strong>{orderHistory.averageDiscount}%</strong></Typography>
                <Typography>🔥 Najczęściej kupowane marki:</Typography>
                <List>
                    {orderHistory.topBrands.map((brand, index) => (
                        <ListItem key={index}>✅ {brand}</ListItem>
                    ))}
                </List>
            </Paper>

            {/* 📜 Tabela zamówień */}
            <Typography variant="h6">📜 Historia Zamówień</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Numer Zamówienia</TableCell>
                            <TableCell>Ilość</TableCell>
                            <TableCell>Wartość</TableCell>
                            <TableCell>Rabat (%)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderHistory.purchases.map((order, index) => {
                            // ✅ Debugowanie: sprawdzamy wartości przed utworzeniem linku
                            console.log("🔗 Generowanie linku do zamówienia:", {
                                username,
                                clientId,
                                orderNumber: order["Dokument Numer"]
                            });

                            // ✅ Upewniamy się, że wartości nie są undefined/null
                            const formattedOrderNumber = order["Dokument Numer"] ? order["Dokument Numer"].replace(/\//g, "-") : "brak-numeru";
                            const formattedClientId = clientId ? encodeURIComponent(clientId) : "brak-clientId";
                            const formattedUsername = username || "brak-username";

                            return (
                                <TableRow key={index}>
                                    <TableCell>{order["Data Wystawienia"]}</TableCell>
                                    <TableCell>
                                        <Link
                                            to={`/order/${formattedUsername}/${formattedClientId}/${formattedOrderNumber}`}
                                            style={{ textDecoration: 'none', color: '#1976d2', fontWeight: 'bold' }}
                                        >
                                            {order["Dokument Numer"]}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{order["Sprzedaż Ilość"]}</TableCell>
                                    <TableCell>{order["Sprzedaż Wartość"]} PLN</TableCell>
                                    <TableCell>{order["Sprzedaż Rabat %"]}%</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>


            {/* 🔙 Przycisk powrotu */}
            <Button 
                component={Link} 
                to="/order-data" 
                variant="contained" 
                color="primary"
                style={{ marginTop: '20px' }}
            >
                Powrót do listy klientów
            </Button>
        </div>
    );
};

export default ClientOrderProfile;
