import React, { useState } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { 
  Grid, TextField, Button, Typography, Box, Select, MenuItem, 
  FormControl, InputLabel, CircularProgress, LinearProgress, 
  useMediaQuery, Card, CardContent, Alert, FormHelperText
} from '@mui/material';
import { 
  CloudUpload, Send, Close as CloseIcon, 
  Receipt as ReceiptIcon
} from '@mui/icons-material';

function Form() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));

  const [formData, setFormData] = useState({
    month: '',
    invoiceNumber: '',
    category: '',
    purpose: '',
    client: '',
    amount: '',
  });

  const [errors, setErrors] = useState({
    invoiceNumber: false,
    category: false,
    amount: false
  });
  
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [fileName, setFileName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
    // Resetuj błąd dla zmienionego pola
    if (errors[name]) {
      setErrors({ ...errors, [name]: false });
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setFileError(false);
      setShowProgress(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Walidacja pól obowiązkowych
    const newErrors = {
      invoiceNumber: !formData.invoiceNumber,
      category: !formData.category,
      amount: !formData.amount
    };
    
    setErrors(newErrors);
    
    // Sprawdź, czy istnieją jakiekolwiek błędy
    if (newErrors.invoiceNumber || newErrors.category || newErrors.amount || !file) {
      if (!file) {
        setFileError(true);
      }
      return;
    }

    setIsSubmitting(true);
    setUploadProgress(0);
    setShowProgress(true);
    setSubmitSuccess(false);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      // Sprawdzenie rozmiaru pliku (max 10MB)
      const maxSize = 10 * 1024 * 1024; // 10MB w bajtach
      if (file.size > maxSize) {
        setFileError(true);
        setIsSubmitting(false);
        alert(`Plik jest zbyt duży (${(file.size / (1024 * 1024)).toFixed(2)}MB). Maksymalny rozmiar to 10MB.`);
        return;
      }

      // Sprawdzenie typu pliku
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
      if (!validTypes.includes(file.type)) {
        // Jeśli Mac wysyła plik z nietypowym typem MIME
        const extension = file.name.split('.').pop().toLowerCase();
        if (!['jpg', 'jpeg', 'png', 'pdf'].includes(extension)) {
          setFileError(true);
          setIsSubmitting(false);
          alert(`Niewspierany format pliku. Dozwolone formaty: JPG, PNG, PDF.`);
          return;
        }
        // Jeśli rozszerzenie jest OK, ale typ MIME nie, próbujemy kontynuować
        console.warn('Typ MIME pliku niezgodny, ale rozszerzenie dozwolone:', file.type);
      }

      const data = new FormData();
      data.append('receipt', file);
      data.append('month', formData.month);
      data.append('invoiceNumber', formData.invoiceNumber);
      data.append('category', formData.category);
      data.append('purpose', formData.purpose);
      data.append('client', formData.client);
      data.append('amount', formData.amount);

      await axios.post('https://api.optimexgroup.com/upload', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      setSubmitSuccess(true);
      setFormData({ month: formData.month, invoiceNumber: '', category: '', purpose: '', client: '', amount: '' });
      setFile(null);
      setFileName('');
      setFileError(false);

      // Resetowanie inputa pliku
      document.getElementById('file-upload-input').value = '';

      // Ukrycie paska postępu po krótkim czasie
      setTimeout(() => setShowProgress(false), 1000);
    } catch (error) {
      console.error('Błąd podczas przesyłania danych:', error);
      setFileError(true);
      
      // Wyświetl bardziej szczegółową informację o błędzie
      let errorMessage = 'Wystąpił błąd podczas przesyłania pliku.';
      
      if (error.response) {
        // Błąd po stronie serwera
        if (error.response.status === 500) {
          errorMessage = 'Błąd serwera podczas przetwarzania pliku. Możliwe przyczyny:\n';
          errorMessage += '- Plik może mieć specjalny format Mac OS X\n';
          errorMessage += '- Spróbuj zmienić format pliku (np. przekonwertuj do PDF)\n';
          errorMessage += '- Zapisz plik ponownie w standardowym formacie JPG';
        } else if (error.response.status === 413) {
          errorMessage = 'Plik jest zbyt duży. Maksymalny rozmiar to 10MB.';
        } else if (error.response.data && error.response.data.message) {
          errorMessage = `Błąd: ${error.response.data.message}`;
        }
      } else if (error.request) {
        // Brak odpowiedzi z serwera
        errorMessage = 'Nie otrzymano odpowiedzi z serwera. Sprawdź połączenie z internetem.';
      }
      
      alert(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderPurposeField = () => {
    if (formData.category === 'SPOTKANIA Z KLIENTAMI') {
      return (
        <FormControl fullWidth>
          <InputLabel>Cel</InputLabel>
          <Select
            name="purpose"
            value={formData.purpose}
            onChange={handleChange}
            label="Cel"
          >
            <MenuItem value="PREZENTACJA OFERTY">PREZENTACJA OFERTY</MenuItem>
            <MenuItem value="PRZYJĘCIE ZAMÓWIENIA">PRZYJĘCIE ZAMÓWIENIA</MenuItem>
            <MenuItem value="OMÓWIENIE WARUNKÓW WSPÓŁPRACY">OMÓWIENIE WARUNKÓW WSPÓŁPRACY</MenuItem>
          </Select>
        </FormControl>
      );
    } else if (formData.category === 'INNE') {
      return (
        <TextField
          fullWidth
          label="Cel"
          name="purpose"
          variant="outlined"
          value={formData.purpose}
          onChange={handleChange}
        />
      );
    } else {
      return (
        <TextField
          fullWidth
          label="Cel (zablokowane)"
          name="purpose"
          variant="outlined"
          value=""
          disabled
        />
      );
    }
  };

  return (
    <Card
      elevation={3}
      sx={{
        borderRadius: { xs: '16px', sm: '20px' },
        overflow: 'hidden',
        boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
        background: (theme) => theme.palette.mode === 'dark' 
          ? 'linear-gradient(135deg, #1A2035 0%, #1E1E2D 100%)' 
          : 'linear-gradient(135deg, #FFFFFF 0%, #F8FAFF 100%)',
      }}
    >
      <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            padding: { xs: '10px', sm: '20px' },
            borderRadius: '12px',
          }}
        >
          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            component="h1"
            align="center"
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              marginBottom: '20px',
              color: (theme) => theme.palette.mode === 'dark' ? '#fff' : '#333',
            }}
          >
            <ReceiptIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
            Wprowadź szczegóły wydatku
          </Typography>
          
          <Typography 
            variant="body2" 
            align="center" 
            sx={{ mb: 2, fontStyle: 'italic' }}
          >
            Pola oznaczone * są wymagane
          </Typography>

          {submitSuccess && (
            <Alert 
              severity="success" 
              sx={{ 
                mb: 3,
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
              }}
              onClose={() => setSubmitSuccess(false)}
            >
              Dane zostały przesłane pomyślnie!
            </Alert>
          )}

          <Grid container spacing={isMobile ? 2 : 3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Miesiąc wydatku</InputLabel>
                <Select
                  name="month"
                  value={formData.month}
                  onChange={handleChange}
                >
                  {['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'].map((month) => (
                    <MenuItem key={month} value={month}>{month}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Numer faktury *"
                name="invoiceNumber"
                variant="outlined"
                value={formData.invoiceNumber}
                onChange={handleChange}
                error={errors.invoiceNumber}
                helperText={errors.invoiceNumber ? "Numer faktury jest wymagany" : ""}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={errors.category}>
                <InputLabel>Kategoria *</InputLabel>
                <Select
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                >
                  {['HOTEL', 'PALIWO', 'AUTOSTRADY', 'PARKINGI', 'SERWIS SAMOCHODU', 'MYJNIA', 'SPOTKANIA Z KLIENTAMI', 'UPOMINKI DLA KLIENTÓW', 'WYNAGRODZENIE', 'INNE'].map((category) => (
                    <MenuItem key={category} value={category}>{category}</MenuItem>
                  ))}
                </Select>
                {errors.category && (
                  <FormHelperText>Kategoria jest wymagana</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {renderPurposeField()}
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Klient"
                name="client"
                variant="outlined"
                value={formData.client}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Kwota *"
                name="amount"
                type="number"
                variant="outlined"
                value={formData.amount}
                onChange={handleChange}
                error={errors.amount}
                helperText={errors.amount ? "Kwota jest wymagana" : ""}
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ 
                border: (theme) => `2px dashed ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.1)'}`,
                borderRadius: '12px',
                p: 3,
                textAlign: 'center',
                position: 'relative',
                transition: 'all 0.3s',
                backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.02)',
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : 'rgba(0,0,0,0.04)',
                  cursor: 'pointer'
                }
              }}>
                <input
                  id="file-upload-input"
                  type="file"
                  hidden
                  accept=".jpg,.jpeg,.png,.pdf"
                  onChange={handleFileChange}
                />
                
                {fileName ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <Typography sx={{ mb: 1 }}>
                      Wybrany plik: {isMobile ? fileName.substring(0, 20) + '...' : fileName}
                    </Typography>
                    {file && (
                      <Typography variant="caption" color="text.secondary" sx={{ mb: 1 }}>
                        Typ: {file.type || 'Nieznany'} | Rozmiar: {(file.size / (1024 * 1024)).toFixed(2)} MB
                      </Typography>
                    )}
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Button 
                        variant="outlined" 
                        color="error" 
                        size="small"
                        startIcon={<CloseIcon />}
                        onClick={() => {
                          setFile(null);
                          setFileName('');
                          setFileError(false);
                          document.getElementById('file-upload-input').value = '';
                        }}
                      >
                        Usuń
                      </Button>
                      <Button 
                        variant="outlined" 
                        color="primary" 
                        size="small"
                        startIcon={<CloudUpload />}
                        component="label"
                      >
                        Zmień
                        <input
                          type="file"
                          accept=".jpg,.jpeg,.png,.pdf"
                          hidden
                          onChange={handleFileChange}
                        />
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box 
                    component="label" 
                    htmlFor="file-upload-input" 
                    sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                  >
                    <CloudUpload sx={{ fontSize: 48, color: 'primary.main', mb: 1 }} />
                    <Typography variant="body1" gutterBottom>
                      Kliknij, aby dodać fakturę *
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Wspierane formaty: JPG, PNG, PDF (max 10MB)
                    </Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ mt: 1, fontStyle: 'italic' }}>
                    
                    </Typography>
                  </Box>
                )}
              </Box>
              
              {fileError && (
                <Typography sx={{ color: 'error.main', mt: 1, textAlign: 'center' }}>
                  {fileName ? 'Wystąpił błąd z plikiem. Spróbuj innego formatu (np. JPG lub PDF).' : 'Proszę dodać plik przed przesłaniem.'}
                </Typography>
              )}
            </Grid>

            {showProgress && (
              <Grid item xs={12}>
                <Box sx={{ position: 'relative', mt: 1, mb: 1 }}>
                  <LinearProgress 
                    variant="determinate" 
                    value={uploadProgress} 
                    sx={{ 
                      height: 8, 
                      borderRadius: 4,
                      '& .MuiLinearProgress-bar': {
                        borderRadius: 4
                      }
                    }}
                  />
                  <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ 
                      position: 'absolute', 
                      right: 0, 
                      top: -20, 
                      fontWeight: 'medium' 
                    }}
                  >
                    {uploadProgress}%
                  </Typography>
                </Box>
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={isSubmitting}
                startIcon={isSubmitting ? undefined : <Send />}
                sx={{
                  padding: '12px',
                  fontWeight: 'bold',
                  borderRadius: '12px',
                  background: 'linear-gradient(45deg, #43a047, #66bb6a)',
                  boxShadow: '0 4px 12px rgba(67,160,71,0.2)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #388e3c, #43a047)',
                    boxShadow: '0 6px 16px rgba(67,160,71,0.3)',
                  },
                }}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} sx={{ color: '#ffffff' }} />
                ) : (
                  'Wyślij'
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}

export default Form;