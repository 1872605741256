import React, { useEffect, useState } from 'react';
import {
  Typography,
  CircularProgress,
  Box,
  Alert,
  useMediaQuery,
  Card,
  CardContent,
  Chip,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
  Divider,
  Grid,
  Paper
} from '@mui/material';
import { 
  Search, History as HistoryIcon, FilterList, ArrowDownward, ArrowUpward,
  OpenInNew, Info as InfoIcon, CalendarMonth, Tune
} from '@mui/icons-material';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import StylishTable from './StylishTable';
import PageHeader from './PageHeader';

// Funkcja do formatowania walutowego z separatorami tysięcznymi
const formatCurrency = (value) => {
  return new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value).replace(/\u00A0/g, ' ');
};

function HistoryPage() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));
  
  const [history, setHistory] = useState([]);
  const [filteredHistory, setFilteredHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [unreturnedSum, setUnreturnedSum] = useState(0);
  const [error, setError] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('desc');
  const [search, setSearch] = useState('');
  const [filterMonth, setFilterMonth] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [showFilters, setShowFilters] = useState(!isMobile);

  // Pobierz dane historii
  useEffect(() => {
    const fetchHistory = async (token) => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get('https://api.optimexgroup.com/history', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.length > 0) {
          const sortedAndLimitedHistory = response.data.reverse();
          setHistory(sortedAndLimitedHistory);
          setFilteredHistory(sortedAndLimitedHistory);

          const totalUnreturned = sortedAndLimitedHistory.reduce((sum, expense) => {
            if (expense.returnedDate === 'Brak') {
              const amount = parseFloat(expense.amount.toString().replace(',', '.') || 0);
              return sum + (isNaN(amount) ? 0 : amount);
            }
            return sum;
          }, 0);

          setUnreturnedSum(totalUnreturned);
        } else {
          console.warn('Brak danych do wyświetlenia dla użytkownika.');
        }
      } catch (error) {
        console.error('Błąd podczas pobierania historii:', error);
        setError('Nie udało się pobrać danych historii. Spróbuj ponownie później.');
      } finally {
        setLoading(false);
      }
    };

    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken();
          fetchHistory(token);
        } catch (authError) {
          console.error('Błąd pobrania tokenu Firebase:', authError);
          setError('Błąd autoryzacji. Spróbuj ponownie się zalogować.');
        }
      }
    });
  }, []);

  // Filtrowanie i sortowanie
  useEffect(() => {
    let result = [...history];
    
    // Filtrowanie
    if (search) {
      const searchLower = search.toLowerCase();
      result = result.filter(item => 
        (item.invoiceNumber && item.invoiceNumber.toLowerCase().includes(searchLower)) ||
        (item.client && item.client.toLowerCase().includes(searchLower)) ||
        (item.category && item.category.toLowerCase().includes(searchLower)) ||
        (item.purpose && item.purpose.toLowerCase().includes(searchLower))
      );
    }
    
    if (filterMonth) {
      result = result.filter(item => item.month === filterMonth);
    }
    
    if (filterStatus) {
      if (filterStatus === 'approved') {
        result = result.filter(item => item.approved === 'Tak');
      } else if (filterStatus === 'pending') {
        result = result.filter(item => item.approved !== 'Tak');
      } else if (filterStatus === 'returned') {
        result = result.filter(item => item.returnedDate !== 'Brak');
      } else if (filterStatus === 'notReturned') {
        result = result.filter(item => item.returnedDate === 'Brak');
      }
    }
    
    // Sortowanie
    if (sortField) {
      result.sort((a, b) => {
        let aValue = a[sortField];
        let bValue = b[sortField];
        
        // Konwersja wartości liczbowych
        if (sortField === 'amount') {
          aValue = parseFloat(a[sortField].toString().replace(',', '.') || 0);
          bValue = parseFloat(b[sortField].toString().replace(',', '.') || 0);
        }
        
        if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
    }
    
    setFilteredHistory(result);
  }, [history, search, filterMonth, filterStatus, sortField, sortDirection]);

  // Funkcja do sortowania
  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  // Obliczamy podsumowanie dla widocznych danych po filtrowaniu
  const filteredTotal = filteredHistory.reduce((sum, expense) => {
    const amount = parseFloat(expense.amount.toString().replace(',', '.') || 0);
    return sum + (isNaN(amount) ? 0 : amount);
  }, 0);

  // Generujemy listę miesięcy z danych
  const availableMonths = [...new Set(history.map(item => item.month))].filter(Boolean);

  const historyTableColumns = [
    { id: 'month', label: 'Miesiąc' },
    { id: 'invoiceNumber', label: 'Numer faktury' },
    { id: 'category', label: 'Kategoria' },
    { id: 'client', label: 'Klient' },
    { 
      id: 'amount', 
      label: 'Kwota', 
      align: 'right',
      format: (value) => formatCurrency(parseFloat(value.toString().replace(',', '.') || 0))
    },
    { 
      id: 'fileLink', 
      label: 'Plik', 
      align: 'center',
      format: (value) => value ? (
        <Tooltip title="Otwórz plik">
          <IconButton
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            size="small"
            color="primary"
          >
            <OpenInNew fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : 'Brak pliku'
    },
    { 
      id: 'approved', 
      label: 'Status',
      align: 'center',
      format: (value) => (
        <Chip 
          label={value === 'Tak' ? 'Zatwierdzony' : 'Oczekujący'}
          color={value === 'Tak' ? 'success' : 'warning'}
          size="small"
          variant="outlined"
        />
      )
    },
    { 
      id: 'returnedDate', 
      label: 'Zwrócono dnia',
      align: 'right',
      format: (value) => value !== 'Brak' ? (
        format(new Date(value), 'dd.MM.yyyy', { locale: pl })
      ) : (
        <Chip 
          label="Nie zwrócono"
          color="error"
          size="small"
          variant="outlined"
        />
      )
    },
  ];

  // Na urządzeniach mobilnych ograniczamy liczbę kolumn
  const responsiveColumns = isMobile 
    ? historyTableColumns.filter(col => ['month', 'invoiceNumber', 'amount', 'approved'].includes(col.id))
    : isTablet
      ? historyTableColumns.filter(col => ['month', 'invoiceNumber', 'amount', 'approved', 'returnedDate'].includes(col.id))
      : historyTableColumns;

  // Panel filtrowania
  const filtersPanel = (
    <Box 
      component={Paper} 
      elevation={2}
      sx={{ 
        p: 2, 
        mb: 3, 
        borderRadius: '16px',
        display: showFilters ? 'block' : 'none'
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            label="Szukaj"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            size={isMobile ? "small" : "medium"}
          />
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth size={isMobile ? "small" : "medium"}>
            <InputLabel id="month-filter-label">Miesiąc</InputLabel>
            <Select
              labelId="month-filter-label"
              value={filterMonth}
              onChange={(e) => setFilterMonth(e.target.value)}
              label="Miesiąc"
              startAdornment={
                <InputAdornment position="start">
                  <CalendarMonth fontSize="small" />
                </InputAdornment>
              }
            >
              <MenuItem value="">Wszystkie</MenuItem>
              {availableMonths.map((month) => (
                <MenuItem key={month} value={month}>{month}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth size={isMobile ? "small" : "medium"}>
            <InputLabel id="status-filter-label">Status</InputLabel>
            <Select
              labelId="status-filter-label"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value="">Wszystkie</MenuItem>
              <MenuItem value="approved">Zatwierdzone</MenuItem>
              <MenuItem value="pending">Oczekujące</MenuItem>
              <MenuItem value="returned">Zwrócone</MenuItem>
              <MenuItem value="notReturned">Niezwrócone</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        
        <Grid item xs={12} sm={6} md={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'flex-start' : 'center' }}>
          <Tooltip title="Sortuj wg daty">
            <IconButton 
              color={sortField === 'month' ? 'primary' : 'default'}
              onClick={() => handleSort('month')}
            >
              {sortField === 'month' && sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />}
            </IconButton>
          </Tooltip>
          
          <Tooltip title="Sortuj wg kwoty">
            <IconButton 
              color={sortField === 'amount' ? 'primary' : 'default'}
              onClick={() => handleSort('amount')}
              sx={{ ml: 1 }}
            >
              {sortField === 'amount' && sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      {/* Ozdobne tło */}
      <Box 
        sx={{
          position: 'fixed',
          top: { xs: -100, md: -200 },
          right: { xs: -100, md: -200 },
          width: { xs: 300, md: 500 },
          height: { xs: 300, md: 500 },
          borderRadius: '50%',
          background: 'radial-gradient(circle, rgba(156,39,176,0.1) 0%, rgba(156,39,176,0) 70%)',
          zIndex: -1
        }}
      />
      
      <PageHeader
        title="Historia Wydatków"
        subtitle="Przegląd i zarządzanie wszystkimi wydatkami"
        icon={<HistoryIcon />}
      />

      <Box sx={{ mb: 4 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={8}>
            <Card 
              elevation={1}
              sx={{ 
                borderRadius: '16px',
                background: (theme) => theme.palette.mode === 'dark' 
                  ? 'linear-gradient(90deg, rgba(66,66,66,0.8) 0%, rgba(42,42,42,0.8) 100%)' 
                  : 'linear-gradient(90deg, #E3F2FD 0%, #BBDEFB 100%)',
                mb: { xs: 2, md: 0 }
              }}
            >
              <CardContent sx={{ p: { xs: 2, md: 3 } }}>
                <Typography 
                  variant={isMobile ? "body1" : "h6"} 
                  component="p" 
                  fontWeight="medium"
                >
                  Suma niezwróconych kosztów:
                  <Box 
                    component="span" 
                    sx={{ 
                      ml: 1,
                      fontWeight: 'bold',
                      fontSize: isMobile ? '1.25rem' : '1.5rem',
                      color: 'error.main'
                    }}
                  >
                    {formatCurrency(unreturnedSum)}
                  </Box>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Card 
              elevation={1}
              sx={{ 
                borderRadius: '16px',
                background: (theme) => theme.palette.mode === 'dark' 
                  ? 'rgba(66,66,66,0.5)'
                  : 'rgba(255,255,255,0.8)',
              }}
            >
              <CardContent sx={{ p: { xs: 2, md: 3 } }}>
                <Typography 
                  variant={isMobile ? "body1" : "h6"} 
                  component="p" 
                  fontWeight="medium"
                >
                  Suma wyfiltrowanych wydatków:
                  <Box 
                    component="span" 
                    sx={{ 
                      ml: 1,
                      fontWeight: 'bold',
                      fontSize: isMobile ? '1.25rem' : '1.5rem',
                      color: 'primary.main'
                    }}
                  >
                    {formatCurrency(filteredTotal)}
                  </Box>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Mobilny przycisk rozwijania filtrów */}
      {isMobile && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <Chip
            icon={<Tune />}
            label={showFilters ? "Ukryj filtry" : "Pokaż filtry"}
            onClick={() => setShowFilters(!showFilters)}
            color="primary"
            variant="outlined"
            sx={{ px: 1 }}
          />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {/* Panel filtrowania */}
      {filtersPanel}

      {/* Tabela historii */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
          <CircularProgress />
        </Box>
      ) : filteredHistory.length === 0 ? (
        <Box 
          sx={{ 
            textAlign: 'center', 
            color: 'text.secondary',
            py: 5,
            px: 2,
            backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.02)',
            borderRadius: '16px',
          }}
        >
          <InfoIcon sx={{ fontSize: 48, mb: 2, opacity: 0.7 }} />
          <Typography variant="h6">Brak wydatków</Typography>
          <Typography variant="body2" sx={{ mt: 1, maxWidth: 500, mx: 'auto' }}>
            {search || filterMonth || filterStatus
              ? 'Nie znaleziono wydatków pasujących do wybranych filtrów. Spróbuj zmienić kryteria wyszukiwania.'
              : 'Nie dodano jeszcze żadnych wydatków do systemu. Użyj formularza zwrotu, aby dodać pierwszy wydatek.'
            }
          </Typography>
        </Box>
      ) : (
        <StylishTable
          columns={responsiveColumns}
          data={filteredHistory}
          summary={{
            month: 'Suma',
            amount: filteredTotal,
          }}
        />
      )}
    </Box>
  );
}

export default HistoryPage;