import React, { useState } from 'react';
import axios from 'axios';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './firebaseConfig';
import { 
  TextField, Button, Typography, Box, Alert, Paper, 
  useMediaQuery, CircularProgress, InputAdornment, IconButton 
} from '@mui/material';
import { Email, Lock, Visibility, VisibilityOff } from '@mui/icons-material';

function Login({ onLoginSuccess }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleLogin = async () => {
    if (!email || !password) {
      setError('Podaj adres e-mail i hasło.');
      return;
    }
    
    setLoading(true);
    setError('');
    
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      onLoginSuccess(userCredential.user);

      // Zapisz logowanie
      await axios.post('/api/auth/log-login', { email: userCredential.user.email })
        .catch(error => console.error('Błąd zapisu logowania:', error));

    } catch (err) {
      let errorMessage = 'Błąd logowania. Sprawdź dane logowania.';
      
      if (err.code === 'auth/invalid-email') {
        errorMessage = 'Nieprawidłowy format adresu e-mail.';
      } else if (err.code === 'auth/user-not-found') {
        errorMessage = 'Nie znaleziono użytkownika z tym adresem e-mail.';
      } else if (err.code === 'auth/wrong-password') {
        errorMessage = 'Nieprawidłowe hasło.';
      } else if (err.code === 'auth/too-many-requests') {
        errorMessage = 'Zbyt wiele nieudanych prób. Spróbuj ponownie później.';
      }
      
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setError('Podaj adres e-mail, aby zresetować hasło.');
      return;
    }
    
    setLoading(true);
    
    try {
      await sendPasswordResetEmail(auth, email);
      setResetMessage('Link do zresetowania hasła został wysłany na podany e-mail.');
      setError('');
    } catch (err) {
      let errorMessage = 'Nie udało się wysłać linku do resetowania hasła.';
      
      if (err.code === 'auth/invalid-email') {
        errorMessage = 'Nieprawidłowy format adresu e-mail.';
      } else if (err.code === 'auth/user-not-found') {
        errorMessage = 'Nie znaleziono użytkownika z tym adresem e-mail.';
      }
      
      setError(errorMessage);
      setResetMessage('');
    } finally {
      setLoading(false);
    }
  };

  // Obsługa klawisza Enter
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        maxWidth: isMobile ? '100%' : 400,
        width: isMobile ? '100%' : 'auto',
        padding: isMobile ? '16px' : '24px',
        backgroundColor: (theme) => theme.palette.background.paper,
        borderRadius: { xs: '12px', sm: '16px' },
        boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
      }}
    >
      {/* Logo Optimex */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
        <img
          src="/optimexlogo.png"
          alt="Optimex Logo"
          style={{ 
            width: isMobile ? '120px' : '150px', 
            height: 'auto',
          }}
        />
      </Box>

      <Typography 
        variant={isMobile ? "h5" : "h4"} 
        component="h1" 
        align="center"
        sx={{ mb: 3, fontWeight: 'bold' }}
      >
        Witamy w systemie
      </Typography>

      <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
        <TextField
          fullWidth
          label="Adres e-mail"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleKeyDown}
          variant="outlined"
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email color="action" />
              </InputAdornment>
            ),
          }}
          sx={{ mb: 2 }}
        />
        
        <TextField
          fullWidth
          label="Hasło"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          variant="outlined"
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock color="action" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
          sx={{ mb: 2 }}
        />

        {error && (
          <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
            {error}
          </Alert>
        )}
        
        {resetMessage && (
          <Alert severity="success" sx={{ mt: 2, mb: 2 }}>
            {resetMessage}
          </Alert>
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
          disabled={loading}
          sx={{ 
            mt: 2,
            mb: 2,
            py: 1.5,
            borderRadius: '8px',
            fontWeight: 600,
            fontSize: '1rem',
            background: 'linear-gradient(45deg, #1976d2, #42a5f5)',
            boxShadow: '0 4px 12px rgba(0,123,255,0.2)',
            '&:hover': {
              background: 'linear-gradient(45deg, #1565c0, #1976d2)',
              boxShadow: '0 6px 16px rgba(0,123,255,0.3)',
            }
          }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Zaloguj się'
          )}
        </Button>

        <Typography
          variant="body2"
          align="center"
          sx={{
            mt: 2,
            color: 'primary.main',
            cursor: 'pointer',
            textDecoration: 'underline',
            '&:hover': {
              color: 'primary.dark',
            }
          }}
          onClick={handlePasswordReset}
        >
          Zapomniałeś hasła? Zresetuj je tutaj
        </Typography>
      </Box>
      
      <Typography 
        variant="caption" 
        color="text.secondary"
        align="center" 
        sx={{ 
          display: 'block',
          mt: 3
        }}
      >
        © {new Date().getFullYear()} Optimex. Wszelkie prawa zastrzeżone.
      </Typography>
    </Paper>
  );
}

export default Login;