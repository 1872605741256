import React from 'react';
import { Card, CardContent, Box, Typography, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import { PaidOutlined, DiscountOutlined, StarOutline, TrendingUp } from '@mui/icons-material';

const StatCard = ({ icon, title, value, unit, color, description }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));
  
  return (
    <Card sx={{ 
      backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(66, 66, 66, 0.8)' : 'white',
      borderRadius: { xs: '12px', sm: '14px', md: '16px' },
      boxShadow: '0 10px 20px rgba(0,0,0,0.08)',
      height: '100%',
      transition: 'all 0.3s ease',
      position: 'relative',
      overflow: 'hidden',
      '&:hover': {
        transform: isMobile ? 'translateY(-4px)' : 'translateY(-8px)',
        boxShadow: '0 15px 30px rgba(0,0,0,0.12)',
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '6px',
        height: '100%',
        background: `linear-gradient(to bottom, ${color}, ${color}90)`,
      }
    }}>
      <CardContent sx={{ p: isMobile ? 2 : 3 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          mb: isMobile ? 1 : 2,
          flexDirection: isMobile ? 'column' : 'row',
          gap: isMobile ? 1 : 0
        }}>
          <Box sx={{ 
            backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : `${color}15`, 
            borderRadius: '12px', 
            p: isMobile ? 1 : 1.5, 
            display: 'flex'
          }}>
            {React.cloneElement(icon, { 
              sx: { 
                fontSize: isMobile ? 22 : 28, 
                color: color,
              } 
            })}
          </Box>
          {description && !isMobile && (
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              px: 1.5,
              py: 0.5,
              borderRadius: '50px',
              backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : `${color}10`,
              color: color
            }}>
              <TrendingUp fontSize="small" sx={{ mr: 0.5 }} />
              <Typography variant="body2" fontWeight="medium">
                {description}
              </Typography>
            </Box>
          )}
        </Box>
        
        <Typography 
          variant={isMobile ? "subtitle1" : "h6"} 
          color="text.secondary" 
          sx={{ mb: 1, fontWeight: 500 }}
        >
          {title}
        </Typography>
        
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'baseline',
          flexWrap: isMobile ? 'wrap' : 'nowrap'
        }}>
          <Typography 
            variant={isMobile ? "h5" : "h4"} 
            component="div" 
            fontWeight="bold"
            sx={{
              background: `linear-gradient(45deg, ${color}, ${color}90)`,
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {typeof value === 'number' 
              ? value.toLocaleString('pl-PL', {minimumFractionDigits: 2, maximumFractionDigits: 2}) 
              : value}
          </Typography>
          <Typography variant={isMobile ? "body2" : "subtitle1"} color="text.secondary" ml={1}>
            {unit}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const BrandsList = ({ brands }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();
  
  return (
    <Paper
      elevation={0}
      sx={{
        p: isMobile ? 2 : 3,
        borderRadius: { xs: '12px', sm: '14px', md: '16px' },
        background: (theme) => theme.palette.mode === 'dark' 
          ? 'linear-gradient(145deg, #2D3748 0%, #1A202C 100%)' 
          : 'linear-gradient(145deg, #EBF8FF 0%, #ffffff 100%)',
        boxShadow: '0 10px 20px rgba(0,0,0,0.05)',
        border: (theme) => theme.palette.mode === 'dark'
          ? '1px solid rgba(255,255,255,0.1)'
          : '1px solid rgba(0,0,0,0.05)',
      }}
    >
      <Typography variant={isMobile ? "subtitle1" : "h6"} fontWeight="bold" mb={2}>
        Najczęściej kupowane marki
      </Typography>
      
      <Grid container spacing={1}>
        {brands.map((brand, index) => (
          <Grid item key={index}>
            <Box
              sx={{
                py: isMobile ? 0.3 : 0.5,
                px: isMobile ? 1.5 : 2,
                borderRadius: '50px',
                backgroundColor: (theme) => 
                  theme.palette.mode === 'dark' ? 'rgba(66, 153, 225, 0.2)' : 'rgba(66, 153, 225, 0.1)',
                color: '#4299E1',
                display: 'inline-block',
                fontWeight: 'medium',
                fontSize: isMobile ? '0.75rem' : '0.875rem',
                border: '1px solid rgba(66, 153, 225, 0.2)',
                mb: isMobile ? 0.5 : 0,
                whiteSpace: 'nowrap'
              }}
            >
              {brand}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

const ClientSummary = ({ summary }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));
  const theme = useTheme();
  
  // Przetwarzanie wartości liczbowych
  const valueAsNumber = () => {
    if (!summary || !summary.totalValue) return 0;
    const cleanValue = summary.totalValue.replace(/\s/g, '');
    return parseFloat(cleanValue.replace(',', '.'));
  };
  
  return (
    <Box sx={{ mb: isMobile ? 2 : 4 }}>
      <Grid container spacing={isMobile ? 2 : 3}>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard 
            icon={<PaidOutlined />}
            title="Łączna wartość zakupów"
            value={valueAsNumber()}
            unit="zł"
            color="#4CAF50"
            description="+12.5%"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard 
            icon={<DiscountOutlined />}
            title="Średni rabat"
            value={parseFloat(summary?.averageDiscount || 0)}
            unit="%"
            color="#FF9800"
            description="Stały"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StatCard 
            icon={<StarOutline />}
            title="Popularne marki"
            value={summary?.topBrands?.length || 0}
            unit="marki"
            color="#2196F3"
            description="Top 5"
          />
        </Grid>
        
        <Grid item xs={12}>
          <BrandsList brands={summary?.topBrands || []} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientSummary;