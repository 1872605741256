import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
    CircularProgress, Typography, Box, Grid, Paper, Tab, Tabs, Button,
    Card, CardContent, Divider, useTheme
} from '@mui/material';
import axios from 'axios';
import StylishTable from './StylishTable';
import ClientSummary from './ClientSummary';
import PageHeader from './PageHeader';
import { CompareArrows, ArrowBack, BarChartOutlined, ShoppingCartOutlined } from '@mui/icons-material';
import ActionButton from './ActionButton';
import { Link } from 'react-router-dom';

const SummaryCard = ({ title, icon, data, color }) => {
    const theme = useTheme();
    
    return (
        <Card sx={{ 
            p: 3, 
            height: '100%', 
            borderRadius: '16px',
            position: 'relative',
            overflow: 'hidden',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '6px',
                height: '100%',
                background: color
            }
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box sx={{ 
                    backgroundColor: `${color}15`, 
                    borderRadius: '12px', 
                    p: 1.5, 
                    mr: 2,
                    display: 'flex'
                }}>
                    {icon}
                </Box>
                <Typography variant="h6" fontWeight="bold">{title}</Typography>
            </Box>
            
            <Divider sx={{ my: 2 }} />
            
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">Liczba dokumentów:</Typography>
                    <Typography variant="h6" fontWeight="bold">{data.documentsCount}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">Łączna wartość:</Typography>
                    <Typography variant="h6" fontWeight="bold">{data.totalValue}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">Średni rabat:</Typography>
                    <Typography variant="h6" fontWeight="bold">{data.averageDiscount}</Typography>
                </Grid>
            </Grid>
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="subtitle2" fontWeight="medium" gutterBottom>Popularne marki:</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {data.topBrands.map((brand, index) => (
                    <Box
                        key={index}
                        sx={{
                            py: 0.5,
                            px: 1.5,
                            borderRadius: '50px',
                            backgroundColor: `${color}10`,
                            color: color,
                            display: 'inline-block',
                            fontWeight: 'medium',
                            fontSize: '0.875rem',
                            border: `1px solid ${color}20`,
                        }}
                    >
                        {brand}
                    </Box>
                ))}
            </Box>
        </Card>
    );
};

const IntegratedProfilePage = () => {
    const { username, clientId } = useParams();
    const navigate = useNavigate();
    const [facturaHistory, setFacturaHistory] = useState([]);
    const [orderHistory, setOrderHistory] = useState([]);
    const [facturaSummary, setFacturaSummary] = useState(null);
    const [orderSummary, setOrderSummary] = useState(null);
    const [comparisonData, setComparisonData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                
                // Pobieranie historii faktur
                const facturaResponse = await axios.get(`/api/client-history/${username}/${encodeURIComponent(clientId)}`).catch(err => {
                    console.warn('Brak danych faktur dla klienta:', err);
                    return { data: { purchases: [], totalValue: "0.00", averageDiscount: "0.00", topBrands: [] } };
                });
                
                // Pobieranie historii zamówień
                const orderResponse = await axios.get(`/api/order-data/${username}/${encodeURIComponent(clientId)}`).catch(err => {
                    console.warn('Brak danych zamówień dla klienta:', err);
                    return { data: { purchases: [], totalValue: "0.00", averageDiscount: "0.00", topBrands: [] } };
                });
                
                // Przetwarzanie danych faktur
                const facturaPurchases = facturaResponse.data.purchases || [];
                const processedFacturaData = processFacturaData(facturaPurchases);
                setFacturaHistory(processedFacturaData);
                
                // Przetwarzanie danych zamówień
                const orderPurchases = orderResponse.data.purchases || [];
                const processedOrderData = processOrderData(orderPurchases);
                setOrderHistory(processedOrderData);
                
                // Ustawienie podsumowań
                setFacturaSummary({
                    totalValue: facturaResponse.data.totalValue || "0.00",
                    averageDiscount: facturaResponse.data.averageDiscount || "0.00",
                    topBrands: facturaResponse.data.topBrands || [],
                    documentsCount: processedFacturaData.length || 0
                });
                
                setOrderSummary({
                    totalValue: orderResponse.data.totalValue || "0.00",
                    averageDiscount: orderResponse.data.averageDiscount || "0.00",
                    topBrands: orderResponse.data.topBrands || [],
                    documentsCount: processedOrderData.length || 0
                });
                
                // Przygotowanie danych porównawczych
                prepareComparisonData(processedFacturaData, processedOrderData);
                
            } catch (error) {
                console.error('❌ Błąd pobierania danych:', error);
                setError('Nie udało się pobrać danych porównawczych.');
            } finally {
                setLoading(false);
            }
        };
        
        fetchData();
    }, [username, clientId]);

    // Przetwarzanie danych faktur
    const processFacturaData = (purchases) => {
        // Przechowujemy unikalne numery faktur, aby uniknąć duplikatów
        const invoiceNumbers = new Set();
        const aggregatedData = {};
        
        purchases.forEach(entry => {
            const invoiceNumber = entry["Dokument Numer"];
            if (!invoiceNumber) return;

            // Dodajemy numer faktury do zbioru unikalnych numerów
            invoiceNumbers.add(invoiceNumber);

            if (!aggregatedData[invoiceNumber]) {
                aggregatedData[invoiceNumber] = {
                    date: entry["Data Wystawienia Dzień"],
                    quantity: 0,
                    value: 0.0,
                    discount: 0,
                    discountCount: 0
                };
            }

            // Konwersja wartości liczbowych
            const quantity = parseInt(entry["Sprzedaż Ilość"]) || 0;
            const value = parseFloat((entry["Sprzedaż Wartość"] || "0").toString().replace(",", ".")) || 0.0;
            const discount = parseFloat((entry["Sprzedaż Rabat %"] || "0").toString().replace(",", ".")) || 0.0;

            // Sumowanie wartości
            aggregatedData[invoiceNumber].quantity += quantity;
            aggregatedData[invoiceNumber].value += value;
            
            if (entry["Sprzedaż Rabat %"]) {
                aggregatedData[invoiceNumber].discount += discount;
                aggregatedData[invoiceNumber].discountCount += 1;
            }
        });

        // Konwersja na tablicę i sortowanie po dacie
        return Object.entries(aggregatedData)
            .map(([invoice, data]) => ({
                type: 'factura',
                invoice,
                date: data.date,
                quantity: data.quantity,
                value: parseFloat(data.value.toFixed(2)),
                discount: data.discountCount > 0 
                    ? (data.discount / data.discountCount).toFixed(2) 
                    : "0.00"
            }))
            .sort((a, b) => new Date(b.date) - new Date(a.date));
    };

    // Przetwarzanie danych zamówień
    const processOrderData = (purchases) => {
        // Przechowujemy unikalne numery zamówień, aby uniknąć duplikatów
        const orderNumbers = new Set();
        const aggregatedData = {};
        
        purchases.forEach(entry => {
            const orderNumber = entry["Dokument Numer"];
            if (!orderNumber) return;

            // Dodajemy numer zamówienia do zbioru unikalnych numerów
            orderNumbers.add(orderNumber);

            if (!aggregatedData[orderNumber]) {
                aggregatedData[orderNumber] = {
                    date: entry["Data Wystawienia Dzień"],
                    quantity: 0,
                    value: 0.0,
                    discount: 0,
                    discountCount: 0
                };
            }

            // Konwersja wartości liczbowych
            const quantity = parseInt(entry["Sprzedaż Ilość"]) || 0;
            const value = parseFloat((entry["Sprzedaż Wartość"] || "0").toString().replace(",", ".")) || 0.0;
            const discount = parseFloat((entry["Sprzedaż Rabat %"] || "0").toString().replace(",", ".")) || 0.0;

            // Sumowanie wartości
            aggregatedData[orderNumber].quantity += quantity;
            aggregatedData[orderNumber].value += value;
            
            if (entry["Sprzedaż Rabat %"]) {
                aggregatedData[orderNumber].discount += discount;
                aggregatedData[orderNumber].discountCount += 1;
            }
        });

        // Konwersja na tablicę i sortowanie po dacie
        return Object.entries(aggregatedData)
            .map(([invoice, data]) => ({
                type: 'order',
                invoice,
                date: data.date,
                quantity: data.quantity,
                value: parseFloat(data.value.toFixed(2)),
                discount: data.discountCount > 0 
                    ? (data.discount / data.discountCount).toFixed(2) 
                    : "0.00"
            }))
            .sort((a, b) => new Date(b.date) - new Date(a.date));
    };

    // Przygotowanie danych porównawczych
    const prepareComparisonData = (facturaData, orderData) => {
        // Łączymy dane z obu źródeł
        const combinedData = [...facturaData, ...orderData];
        
        // Grupujemy według daty, licząc różnice
        const groupedByDate = {};
        
        combinedData.forEach(item => {
            const dateKey = item.date;
            
            if (!groupedByDate[dateKey]) {
                groupedByDate[dateKey] = {
                    date: dateKey,
                    factura_quantity: 0,
                    factura_value: 0,
                    order_quantity: 0,
                    order_value: 0
                };
            }
            
            if (item.type === 'factura') {
                groupedByDate[dateKey].factura_quantity += item.quantity;
                groupedByDate[dateKey].factura_value += item.value;
            } else {
                groupedByDate[dateKey].order_quantity += item.quantity;
                groupedByDate[dateKey].order_value += item.value;
            }
        });
        
        // Konwersja na tablicę i dodanie różnic
        const comparisonResult = Object.values(groupedByDate)
            .map(item => ({
                ...item,
                quantity_diff: item.factura_quantity - item.order_quantity,
                value_diff: item.factura_value - item.order_value
            }))
            .sort((a, b) => new Date(b.date) - new Date(a.date));
            
        setComparisonData(comparisonResult);
    };

    // Obsługa zmiany zakładki
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div style={{ padding: '16px', maxWidth: '1200px', margin: 'auto' }}>
            <PageHeader 
                title={`Porównanie historii: ${decodeURIComponent(clientId)}`} 
                subtitle="Zestawienie faktur i zamówień z możliwością analizy różnic"
                icon={<CompareArrows />}
            />

            {loading && <CircularProgress style={{ display: 'block', margin: '40px auto' }} />}
            {error && <Typography color="error" style={{ textAlign: 'center', marginTop: '20px' }}>{error}</Typography>}

            {!loading && !error && (
                <>
                    {/* Podsumowanie - porównanie wartości */}
                    <Grid container spacing={3} sx={{ mb: 4 }}>
                        <Grid item xs={12} md={6}>
                            <SummaryCard 
                                title="System faktur" 
                                icon={<BarChartOutlined sx={{ fontSize: 24, color: '#1976d2' }} />}
                                data={facturaSummary || {
                                    totalValue: "0.00 zł",
                                    averageDiscount: "0.00%",
                                    topBrands: [],
                                    documentsCount: 0
                                }}
                                color="#1976d2"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SummaryCard 
                                title="System zamówień" 
                                icon={<ShoppingCartOutlined sx={{ fontSize: 24, color: '#4caf50' }} />}
                                data={orderSummary || {
                                    totalValue: "0.00 zł",
                                    averageDiscount: "0.00%",
                                    topBrands: [],
                                    documentsCount: 0
                                }}
                                color="#4caf50"
                            />
                        </Grid>
                    </Grid>

                    {/* Action Buttons */}
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 4, justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={`/client-profile/${username}/${clientId}`}
                            startIcon={<BarChartOutlined />}
                            sx={{ borderRadius: '8px', px: 3, py: 1 }}
                        >
                            Historia faktur
                        </Button>
                        
                        <Button
                            variant="contained"
                            color="success"
                            component={Link}
                            to={`/order-profile/${username}/${encodeURIComponent(clientId)}`}
                            startIcon={<ShoppingCartOutlined />}
                            sx={{ borderRadius: '8px', px: 3, py: 1 }}
                        >
                            Historia zamówień
                        </Button>
                    </Box>

                    {/* Tabs do przełączania widoków */}
                    <Box sx={{ 
                        borderBottom: 1, 
                        borderColor: 'divider', 
                        mb: 3,
                        backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.02)',
                        borderRadius: '8px'
                    }}>
                        <Tabs 
                            value={tabValue} 
                            onChange={handleChangeTab} 
                            centered
                            textColor="primary"
                            indicatorColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                        >
                            <Tab label="Porównanie zbiorcze" />
                            <Tab label="Faktury" />
                            <Tab label="Zamówienia" />
                        </Tabs>
                    </Box>

                    {/* Tabela porównawcza - Tab 0 */}
                    {tabValue === 0 && (
                        <Box sx={{ overflowX: 'auto' }}>
                            <Typography variant="h6" gutterBottom>Porównanie według dat</Typography>
                            <Box sx={{ minWidth: { xs: '800px', md: '100%' } }}>
                                <StylishTable
                                    columns={[
                                        { id: 'date', label: 'Data' },
                                        { id: 'factura_quantity', label: 'Ilość (Faktury)', align: 'right' },
                                        { id: 'order_quantity', label: 'Ilość (Zamówienia)', align: 'right' },
                                        { id: 'quantity_diff', label: 'Różnica ilości', align: 'right', 
                                          format: (value) => {
                                            const color = value < 0 ? 'red' : value > 0 ? 'green' : 'inherit';
                                            return <span style={{ color, fontWeight: 'bold' }}>{value}</span>;
                                          } 
                                        },
                                        { id: 'factura_value', label: 'Wartość (Faktury)', align: 'right',
                                          format: (value) => value.toLocaleString('pl-PL', { minimumFractionDigits: 2 }) + ' zł'
                                        },
                                        { id: 'order_value', label: 'Wartość (Zamówienia)', align: 'right',
                                          format: (value) => value.toLocaleString('pl-PL', { minimumFractionDigits: 2 }) + ' zł'
                                        },
                                        { id: 'value_diff', label: 'Różnica wartości', align: 'right',
                                          format: (value) => {
                                            const color = value < 0 ? 'red' : value > 0 ? 'green' : 'inherit';
                                            return <span style={{ color, fontWeight: 'bold' }}>
                                                {value.toLocaleString('pl-PL', { minimumFractionDigits: 2 })} zł
                                            </span>;
                                          }
                                        },
                                    ]}
                                    data={comparisonData}
                                />
                            </Box>
                        </Box>
                    )}

                    {/* Tabela faktur - Tab 1 */}
                    {tabValue === 1 && (
                        <Box sx={{ overflowX: 'auto' }}>
                            <Typography variant="h6" gutterBottom>Historia faktur</Typography>
                            <Box sx={{ minWidth: { xs: '800px', md: '100%' } }}>
                                <StylishTable
                                    columns={[
                                        { id: 'date', label: 'Data' },
                                        { id: 'invoice', label: 'Numer Faktury', format: (value) => (
                                            <Link to={`/invoice/${username}/${clientId}/${value.replace(/\//g, "-")}`} 
                                                style={{ textDecoration: 'none', color: '#1976d2', fontWeight: 'bold' }}>
                                                {value}
                                            </Link>
                                        )},
                                        { id: 'quantity', label: 'Ilość', align: 'right' },
                                        { id: 'value', label: 'Wartość (zł)', align: 'right', format: (value) => 
                                            value.toLocaleString('pl-PL', { minimumFractionDigits: 2 })
                                        },
                                        { id: 'discount', label: 'Rabat (%)', align: 'right', format: (value) => `${value}%` },
                                    ]}
                                    data={facturaHistory}
                                />
                            </Box>
                        </Box>
                    )}

                    {/* Tabela zamówień - Tab 2 */}
                    {tabValue === 2 && (
                        <Box sx={{ overflowX: 'auto' }}>
                            <Typography variant="h6" gutterBottom>Historia zamówień</Typography>
                            <Box sx={{ minWidth: { xs: '800px', md: '100%' } }}>
                                <StylishTable
                                    columns={[
                                        { id: 'date', label: 'Data' },
                                        { id: 'invoice', label: 'Numer Zamówienia', format: (value) => (
                                            <Link to={`/order/${username}/${clientId}/${value.replace(/\//g, "-")}`} 
                                                style={{ textDecoration: 'none', color: '#1976d2', fontWeight: 'bold' }}>
                                                {value}
                                            </Link>
                                        )},
                                        { id: 'quantity', label: 'Ilość', align: 'right' },
                                        { id: 'value', label: 'Wartość (zł)', align: 'right', format: (value) => 
                                            value.toLocaleString('pl-PL', { minimumFractionDigits: 2 })
                                        },
                                        { id: 'discount', label: 'Rabat (%)', align: 'right', format: (value) => `${value}%` },
                                    ]}
                                    data={orderHistory}
                                />
                            </Box>
                        </Box>
                    )}

                    {/* Przyciski nawigacyjne */}
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mt: 4 }}>
                        <ActionButton
                            icon={<ArrowBack />}
                            label="Wróć do przeglądu"
                            onClick={() => navigate('/integrated-data')}
                        />
                    </Box>
                </>
            )}
        </div>
    );
};

export default IntegratedProfilePage;