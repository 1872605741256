import React, { useState, useEffect } from 'react';
import { 
    CircularProgress, Typography, Box, Paper, Chip, Button,
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, InputAdornment, IconButton
} from '@mui/material';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import PageHeader from './PageHeader';
import { Assignment, Visibility, Event, LocationOn, Warning, Search, Clear } from '@mui/icons-material';
import StylishTable from './StylishTable';

const ROPendingPage = () => {
    const navigate = useNavigate();
    const [pendingOrders, setPendingOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [clientNameFilter, setClientNameFilter] = useState('');
    
    const user = JSON.parse(localStorage.getItem('user'));
    const username = user ? user.email.split('@')[0] : null;

    const fetchPendingOrders = async (filterName = '') => {
        try {
            setLoading(true);
            if (!username) {
                throw new Error('Brak nazwy użytkownika.');
            }

            let url = `/api/ro-pending?username=${username}`;
            if (filterName) {
                url += `&clientName=${encodeURIComponent(filterName)}`;
            }

            const response = await axios.get(url);
            setPendingOrders(response.data);
        } catch (error) {
            console.error('Błąd podczas pobierania zamówień do realizacji:', error);
            setError('Nie udało się pobrać listy zamówień do realizacji.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPendingOrders();
    }, [username]);

    const handleRowClick = (order) => {
        setSelectedOrder(order);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleFilterChange = (event) => {
        setClientNameFilter(event.target.value);
    };

    const handleSearchClick = () => {
        fetchPendingOrders(clientNameFilter);
    };

    const handleClearFilter = () => {
        setClientNameFilter('');
        fetchPendingOrders('');
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        
        try {
            const date = new Date(dateString);
            return date.toLocaleDateString('pl-PL', { 
                year: 'numeric', 
                month: '2-digit', 
                day: '2-digit' 
            });
        } catch (error) {
            console.error('Błąd formatowania daty:', error);
            return dateString;
        }
    };

    const calculateTotalValue = (products) => {
        if (!products || !Array.isArray(products)) return 0;
        
        return products.reduce((sum, product) => {
            return sum + product.value;
        }, 0);
    };

    return (
        <Box sx={{ padding: '20px', maxWidth: '1200px', margin: 'auto' }}>
            <PageHeader 
                title="RO do realizacji" 
                subtitle="Lista zamówień oczekujących na realizację"
                icon={<Assignment />}
            />

            {/* Filtr po nazwie kontrahenta */}
            <Paper sx={{ p: 2, mb: 3, borderRadius: '16px', display: 'flex', alignItems: 'center' }}>
                <TextField
                    fullWidth
                    label="Filtruj po nazwie kontrahenta"
                    variant="outlined"
                    value={clientNameFilter}
                    onChange={handleFilterChange}
                    onKeyPress={handleKeyPress}
                    sx={{ flexGrow: 1 }}
                    InputProps={{
                        endAdornment: clientNameFilter && (
                            <InputAdornment position="end">
                                <IconButton onClick={handleClearFilter} edge="end">
                                    <Clear />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Button
                    variant="contained"
                    startIcon={<Search />}
                    onClick={handleSearchClick}
                    sx={{ ml: 2, height: 56 }}
                >
                    Szukaj
                </Button>
            </Paper>

            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                    <CircularProgress />
                </Box>
            )}
            
            {error && (
                <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'error.light', color: 'error.contrastText', p: 2, borderRadius: 1, mb: 3 }}>
                    <Warning sx={{ mr: 1 }} />
                    <Typography>{error}</Typography>
                </Box>
            )}

            {!loading && !error && pendingOrders.length === 0 && (
                <Paper sx={{ p: 3, textAlign: 'center', borderRadius: '16px' }}>
                    <Typography variant="h6">Brak zamówień do realizacji</Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        {clientNameFilter 
                            ? `Nie znaleziono zamówień dla kontrahenta "${clientNameFilter}".` 
                            : 'Aktualnie nie masz żadnych zamówień czekających na realizację.'}
                    </Typography>
                </Paper>
            )}

            {!loading && !error && pendingOrders.length > 0 && (
                <StylishTable
                    columns={[
                        { id: 'orderNumber', label: 'Numer RO', format: (value) => (
                            <Box sx={{ 
                                cursor: 'pointer', 
                                color: 'primary.main', 
                                fontWeight: 'bold',
                                textDecoration: 'underline',
                                '&:hover': { color: 'primary.dark' }
                            }}>
                                {value}
                            </Box>
                        )},
                        { id: 'clientName', label: 'Klient' },
                        { id: 'city', label: 'Miasto', format: (value, row) => (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <LocationOn fontSize="small" sx={{ mr: 0.5, color: 'action.active' }} />
                                <span>{value}{row.region ? ` (${row.region})` : ''}</span>
                            </Box>
                        )},
                        { id: 'date', label: 'Data zamówienia', format: (value) => (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Event fontSize="small" sx={{ mr: 0.5, color: 'action.active' }} />
                                <span>{formatDate(value)}</span>
                            </Box>
                        )},
                        { id: 'products', label: 'Liczba produktów', align: 'right', format: (value) => 
                            Array.isArray(value) ? value.length : 0
                        },
                        { id: 'value', label: 'Wartość całkowita', align: 'right', format: (_, row) => 
                            `${calculateTotalValue(row.products).toLocaleString('pl-PL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} zł`
                        },
                        { id: 'actions', label: 'Akcje', align: 'center', format: () => (
                            <Button
                                variant="outlined"
                                size="small"
                                startIcon={<Visibility />}
                                sx={{ borderRadius: '8px' }}
                            >
                                Szczegóły
                            </Button>
                        )}
                    ]}
                    data={pendingOrders}
                    onRowClick={handleRowClick}
                />
            )}

            {/* Dialog ze szczegółami zamówienia */}
            <Dialog 
                open={openDialog} 
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
            >
                {selectedOrder && (
                    <>
                        <DialogTitle>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h6">
                                    Szczegóły zamówienia: {selectedOrder.orderNumber}
                                </Typography>
                                <Chip 
                                    label="Do realizacji"
                                    color="warning"
                                    size="small"
                                    variant="outlined"
                                />
                            </Box>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="subtitle1" gutterBottom>Informacje o kliencie</Typography>
                                <Paper sx={{ p: 2 }} variant="outlined">
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                        <Typography><strong>Klient:</strong> {selectedOrder.clientName}</Typography>
                                        <Typography><strong>Miasto:</strong> {selectedOrder.city}</Typography>
                                        <Typography><strong>Województwo:</strong> {selectedOrder.region}</Typography>
                                        <Typography><strong>Data zamówienia:</strong> {formatDate(selectedOrder.date)}</Typography>
                                        <Typography><strong>Handlowiec:</strong> {selectedOrder.salesRep}</Typography>
                                    </Box>
                                </Paper>
                            </Box>

                            <Typography variant="subtitle1" gutterBottom>Produkty do realizacji</Typography>
                            <StylishTable
                                columns={[
                                    { id: 'productName', label: 'Nazwa produktu' },
                                    { id: 'brand', label: 'Marka' },
                                    { id: 'producer', label: 'Producent' },
                                    { id: 'quantity', label: 'Ilość zamówiona', align: 'right' },
                                    { id: 'remainingQuantity', label: 'Ilość do realizacji', align: 'right', 
                                      format: (value) => (
                                        <Chip 
                                            label={value}
                                            color={value > 0 ? "warning" : "success"}
                                            size="small"
                                        />
                                      )
                                    },
                                    { id: 'value', label: 'Wartość', align: 'right', format: (value) => 
                                        `${value.toLocaleString('pl-PL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} zł`
                                    },
                                ]}
                                data={selectedOrder.products}
                                summary={{
                                    productName: 'Podsumowanie',
                                    quantity: selectedOrder.products.reduce((sum, product) => sum + product.quantity, 0),
                                    remainingQuantity: selectedOrder.products.reduce((sum, product) => sum + product.remainingQuantity, 0),
                                    value: calculateTotalValue(selectedOrder.products)
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                onClick={handleCloseDialog}
                                variant="outlined"
                            >
                                Zamknij
                            </Button>
                            <Button 
                                component={Link}
                                to={`/ro-pending-details/${selectedOrder.orderNumber}`}
                                variant="contained"
                                color="primary"
                            >
                                Pełny widok
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </Box>
    );
};

export default ROPendingPage;