import React, { useState, useEffect } from 'react';
import {
    Box, Typography, Paper, CircularProgress, Button, 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField, Alert, FormControl, InputLabel, Select, MenuItem,
    IconButton, Snackbar, Card, CardContent, Grid, Divider, Chip
} from '@mui/material';
import { 
    Settings, ArrowBack, CheckCircle, Error,
    Info, Person, FilterList
} from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PageHeader from './PageHeader';

const ROAssignmentPage = () => {
    const navigate = useNavigate();
    const [userHandlers, setUserHandlers] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    
    const user = JSON.parse(localStorage.getItem('user'));
    const username = user ? user.email.split('@')[0] : null;

    // Pobierz dane użytkowników i ich przypisania
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                
                // Pobierz informacje o uprawnieniach wszystkich użytkowników
                const response = await axios.get('/api/ro-check-access');
                
                // Użyj nowych danych do aktualizacji stanu
                const userData = response.data;
                const handlerMap = {};
                
                userData.forEach(user => {
                    handlerMap[user.username] = user.handlers;
                });
                
                setUserHandlers(handlerMap);
            } catch (error) {
                console.error('Błąd podczas pobierania danych:', error);
                setError('Nie udało się pobrać danych przyporządkowania.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Sprawdź, czy przyporządkowania są zdefiniowane w kodzie
    const isCurrentUserInConfig = userHandlers[username] !== undefined;

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    // Lista wszystkich użytkowników
    const allUsers = [
        "adam.dryjanski",
        "albert.michalski",
        "artur.trzpiola",
        "igor.kwiatkowski",
        "marcin.krukowski",
        "marek.karas",
        "mateusz.florczak",
        "monika.ochmanska"
    ];

    return (
        <Box sx={{ padding: '20px', maxWidth: '1200px', margin: 'auto' }}>
            <PageHeader 
                title="Informacje o przyporządkowaniu" 
                subtitle="Przypisane wartości z kolumny 'Dokument Atrybut HANDLOWIEC'"
                icon={<Settings />}
            />

            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                    <CircularProgress />
                </Box>
            )}
            
            {error && (
                <Alert 
                    severity="error"
                    sx={{ mb: 3 }}
                >
                    {error}
                </Alert>
            )}

            {!loading && !error && (
                <>
                    <Alert 
                        severity="info" 
                        icon={<Info />}
                        sx={{ mb: 3 }}
                    >
                        Przyporządkowania są zdefiniowane bezpośrednio w kodzie serwera. Ta strona pokazuje aktualne przypisania 
                        wartości z kolumny "Dokument Atrybut HANDLOWIEC" do poszczególnych użytkowników.
                    </Alert>

                    {!isCurrentUserInConfig && (
                        <Alert 
                            severity="warning" 
                            sx={{ mb: 3 }}
                        >
                            Nie masz żadnych przypisanych wartości w konfiguracji. Skontaktuj się z administratorem.
                        </Alert>
                    )}

                    <Paper sx={{ p: 3, mb: 4, borderRadius: '16px' }}>
                        <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                            <Person sx={{ mr: 1 }} />
                            Twoje przypisane wartości
                        </Typography>
                        
                        {userHandlers[username] && userHandlers[username].length > 0 ? (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                {userHandlers[username].map((handler, index) => (
                                    <Chip 
                                        key={index}
                                        label={handler}
                                        color="primary"
                                        variant="outlined"
                                    />
                                ))}
                            </Box>
                        ) : (
                            <Typography color="text.secondary">
                                Nie masz przypisanych żadnych wartości.
                            </Typography>
                        )}
                    </Paper>

                    <Paper sx={{ p: 3, borderRadius: '16px' }}>
                        <Typography variant="h6" sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
                            <FilterList sx={{ mr: 1 }} />
                            Wszystkie przyporządkowania
                        </Typography>

                        <Grid container spacing={3}>
                            {allUsers.map(user => (
                                <Grid item xs={12} md={6} lg={4} key={user}>
                                    <Card variant="outlined" sx={{ height: '100%' }}>
                                        <CardContent>
                                            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                                                {user}
                                            </Typography>
                                            <Divider sx={{ mb: 2 }} />
                                            
                                            {userHandlers[user] && userHandlers[user].length > 0 ? (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                                    {userHandlers[user].map((handler, index) => (
                                                        <Chip 
                                                            key={index}
                                                            label={handler}
                                                            size="small"
                                                            color={user === username ? "primary" : "default"}
                                                            variant="outlined"
                                                        />
                                                    ))}
                                                </Box>
                                            ) : (
                                                <Typography color="text.secondary" variant="body2">
                                                    Brak przypisanych wartości
                                                </Typography>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
                        <Button
                            variant="outlined"
                            startIcon={<ArrowBack />}
                            onClick={() => navigate('/ro-pending')}
                        >
                            Powrót do listy zamówień
                        </Button>
                    </Box>
                </>
            )}

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                    icon={snackbar.severity === 'success' ? <CheckCircle /> : <Error />}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ROAssignmentPage;