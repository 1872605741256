import React, { useState, useEffect } from 'react';
import { 
    CircularProgress, Typography, Table, TableBody, TableCell, 
    TableContainer, TableHead, TableRow, Paper, Autocomplete, TextField, Button,
    Box, Container, Grid, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import axios from 'axios';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Link } from 'react-router-dom';
import { LocationCity } from '@mui/icons-material';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28BFF', '#FF6384', '#36A2EB'];

const OrderDataPage = () => {
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [clientData, setClientData] = useState([]);
    const [salesByYear, setSalesByYear] = useState({});
    const [years, setYears] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [producerShare, setProducerShare] = useState([]);
    const [discountByBrand, setDiscountByBrand] = useState({});
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    const username = user ? user.email.split('@')[0] : null;

    // Fetch all cities and clients
    useEffect(() => {
        const fetchCitiesAndClients = async () => {
            try {
                if (!username) {
                    throw new Error('Brak nazwy użytkownika.');
                }
                setLoading(true);
                const headers = token ? { Authorization: `Bearer ${token}` } : {};
                
                // Get all cities
                const citiesResponse = await axios.get(`/api/cities?username=${username}`, { headers })
                    .catch(err => {
                        console.warn('Błąd pobierania miast:', err);
                        return { data: [] };
                    });
                
                setCities(citiesResponse.data);
                
                // Get all clients
                const clientsResponse = await axios.get(`/api/order-clients?username=${username}`, { headers });
                let clientsList = clientsResponse.data;
                
                if (clientsList.length > 0 && clientsList[0] === "Kontrahent Nazwa") {
                    clientsList.shift();
                }
                
                setClients(clientsList);
                setFilteredClients(clientsList);
                
            } catch (error) {
                console.error('Błąd podczas pobierania danych:', error);
                setError('Nie udało się pobrać listy miast lub klientów.');
            } finally {
                setLoading(false);
            }
        };
        
        fetchCitiesAndClients();
    }, [token, username]);
    
    // Filter clients when city selection changes
    useEffect(() => {
        const updateFilteredClients = async () => {
            try {
                if (!selectedCity) {
                    // If no city is selected, show all clients
                    setFilteredClients(clients);
                } else {
                    // If a city is selected, fetch clients for that city
                    setLoading(true);
                    const headers = token ? { Authorization: `Bearer ${token}` } : {};
                    
                    // Get clients filtered by city
                    const response = await axios.get(`/api/clients?username=${username}&city=${encodeURIComponent(selectedCity)}`, { headers });
                    setFilteredClients(response.data);
                }
            } catch (error) {
                console.error('Błąd podczas filtrowania klientów według miasta:', error);
                setError('Nie udało się przefiltrować klientów według miasta.');
            } finally {
                setLoading(false);
            }
        };
        
        updateFilteredClients();
    }, [selectedCity, clients, token, username]);

    const handleSelectClient = async (_, client) => {
        if (!client) return;
    
        setSelectedClient(client.trim());
    
        setClientData([]); // Resetujemy stare dane
        setLoading(true);
        setError('');
    
        try {
            if (!username) {
                throw new Error('Brak nazwy użytkownika.');
            }
            const headers = token ? { Authorization: `Bearer ${token}` } : {};
            const response = await axios.get(`/api/order-data/${username}/${encodeURIComponent(client)}`, { headers });

            if (!response.data || !response.data.purchases || response.data.purchases.length === 0) {
                console.warn("⚠️ Brak danych zamówień dla klienta:", client);
                setError("Brak zamówień dla tego klienta.");
                return;
            }

            setClientData(response.data.purchases);
            console.log("✅ Dane zamówień pobrane:", response.data.purchases);
        } catch (error) {
            console.error('Błąd podczas pobierania danych klienta:', error);
            setError('Nie udało się pobrać danych klienta.');
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        if (clientData.length > 0) {
            const processSalesData = () => {
                const salesByYear = {};
                const years = new Set();
                const producerCounts = {};
                const discountByBrand = {}; // 🆕 Nowa struktura do przechowywania rabatów
                
                clientData.forEach(item => {
                    const brand = item['Produkt Marka'];
                    const producer = item['Produkt Producent'];
                    if (!brand || !producer) return;

                    let dateStr = item['Data Wystawienia Dzień'];
                    if (!dateStr || typeof dateStr !== "string") return;

                    const year = dateStr.split("-")[0]; 
                    const parsedYear = parseInt(year, 10);
                    if (isNaN(parsedYear) || parsedYear < 2000 || parsedYear > new Date().getFullYear() + 1) {
                        console.warn("⚠️ Nieprawidłowa data w rekordzie:", dateStr);
                        return;
                    }

                    let quantity = parseInt(item['Sprzedaż Ilość'], 10);
                    if (isNaN(quantity)) {
                        quantity = 0;
                    }

                    // 📊 Tabela sprzedaży na lata i marki
                    if (!salesByYear[brand]) {
                        salesByYear[brand] = {};
                    }
                    if (!salesByYear[brand][parsedYear]) {
                        salesByYear[brand][parsedYear] = 0;
                    }
                    salesByYear[brand][parsedYear] += quantity;
                    years.add(parsedYear);

                    // 🥧 Wykres kołowy - procentowy udział producenta
                    if (!producerCounts[producer]) {
                        producerCounts[producer] = 0;
                    }
                    producerCounts[producer] += quantity;
                    
                    // 🎯 Tabela rabatów na marki
                    let discount = 0;
                    if (item['Sprzedaż Rabat %']) {
                        // Always replace comma with dot first, then parse
                        discount = parseFloat((item['Sprzedaż Rabat %']).toString().replace(',', '.'));
                        if (isNaN(discount)) {
                            discount = 0;
                        }
                    }
                    if (!discountByBrand[brand]) {
                        discountByBrand[brand] = [];
                    }
                    discountByBrand[brand].push(discount);
                });

                // 📊 Ustawienie danych sprzedaży na lata i marki
                setSalesByYear({ ...salesByYear }); 
                setYears([...years].sort());
                
                // 🎯 Oblicz średni rabat na markę
                const avgDiscountByBrand = {};
                Object.keys(discountByBrand).forEach(brand => {
                    const discounts = discountByBrand[brand];
                    const avgDiscount = discounts.length > 0 
                        ? discounts.reduce((acc, val) => acc + val, 0) / discounts.length 
                        : 0; 
                    // Use toFixed(2) to keep 2 decimal places instead of rounding to whole number
                    avgDiscountByBrand[brand] = parseFloat(avgDiscount.toFixed(2));
                });
                setDiscountByBrand(avgDiscountByBrand);

                // 🥧 Wykres kołowy - procentowy udział producenta
                const totalQuantity = Object.values(producerCounts).reduce((acc, val) => acc + val, 0);
                const producerData = Object.keys(producerCounts).map((producer, index) => ({
                    name: producer,
                    value: Math.round((producerCounts[producer] / totalQuantity) * 100),
                    color: COLORS[index % COLORS.length],
                }));
                setProducerShare(producerData);
            };

            processSalesData();
        }
    }, [clientData]);

    return (
        <Container maxWidth="lg">
            <Box sx={{ padding: '20px', maxWidth: '100%', margin: 'auto' }}>
                <Typography variant="h4" gutterBottom>
                    Dane Klienta - Zamówienia
                </Typography>

                {/* Informacja o aktywnym filtrze miasta */}
                {selectedCity && (
                    <Box sx={{ 
                        mb: 2, 
                        p: 1, 
                        borderRadius: '8px',
                        backgroundColor: 'primary.main',
                        color: 'white',
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: 1
                    }}>
                        <LocationCity fontSize="small" />
                        <Typography variant="body2" fontWeight="medium">
                            Filtr miasta: {selectedCity}
                        </Typography>
                    </Box>
                )}

                {/* Filtry - miasto i klient */}
                <Box sx={{ 
                    width: '100%', 
                    maxWidth: '100%',
                    mb: 3
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                options={cities}
                                value={selectedCity}
                                onChange={(event, newValue) => {
                                    setSelectedCity(newValue);
                                    setSelectedClient(null); // Reset selected client when city changes
                                }}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        label="Filtruj według miasta" 
                                        variant="outlined" 
                                        placeholder="Wszystkie miasta"
                                        fullWidth
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <>
                                                    <LocationCity color="action" sx={{ mr: 1 }} />
                                                    {params.InputProps.startAdornment}
                                                </>
                                            )
                                        }}
                                    />
                                )}
                                fullWidth
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '12px'
                                    }
                                }}
                                clearOnBlur={false}
                                clearOnEscape={true}
                                disableClearable={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Autocomplete
                                options={filteredClients}
                                value={selectedClient}
                                onChange={handleSelectClient}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        label={selectedCity ? `Wybierz klienta z ${selectedCity}` : "Wybierz klienta"} 
                                        variant="outlined" 
                                        fullWidth 
                                    />
                                )}
                                fullWidth
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '12px'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                {selectedClient && (
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'flex-end', 
                        gap: '10px', 
                        mt: 3,
                        mb: 3
                    }}>
                        <Button 
                            component={Link} 
                            to={selectedClient ? `/order-profile/${username}/${encodeURIComponent(selectedClient)}` : "#"} 
                            variant="contained" 
                            color="primary" 
                            disabled={!selectedClient}
                            sx={{
                                borderRadius: '8px',
                                py: 1.2,
                                px: 3
                            }}
                        >
                            Zobacz historię zamówień
                        </Button>
                    </Box>
                )}

                {loading && <CircularProgress sx={{ display: 'block', mx: 'auto', my: 4 }} />}
                {error && <Typography color="error" sx={{ mt: 2, mb: 2 }}>{error}</Typography>}

                {/* 📊 Tabela sprzedaży na lata i marki */}
                {Object.keys(salesByYear).length > 0 && (
                    <Box sx={{ mt: 4, mb: 4 }}>
                        <Typography variant="h6" gutterBottom>📊 Podział sprzedaży na lata</Typography>
                        <TableContainer component={Paper} sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: 'primary.main' }}>
                                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Marka</TableCell>
                                        {years.map(year => (
                                            <TableCell key={year} align="right" sx={{ color: 'white', fontWeight: 'bold' }}>{year}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(salesByYear).map((brand, index) => (
                                        <TableRow key={brand} sx={{ backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.02)' : 'transparent' }}>
                                            <TableCell sx={{ fontWeight: 'medium' }}>{brand}</TableCell>
                                            {years.map(year => (
                                                <TableCell key={year} align="right">
                                                    {salesByYear[brand][year] || 0}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}

                {/* 🎯 Tabela średniego rabatu na markę */}
                {Object.keys(discountByBrand).length > 0 && (
                    <Box sx={{ mt: 4, mb: 4 }}>
                        <Typography variant="h6" gutterBottom>🎯 Średni rabat udzielony na markę</Typography>
                        <TableContainer component={Paper} sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: 'secondary.main' }}>
                                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Marka</TableCell>
                                        <TableCell align="right" sx={{ color: 'white', fontWeight: 'bold' }}>Średni Rabat (%)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(discountByBrand).map((brand, index) => (
                                        <TableRow key={brand} sx={{ backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.02)' : 'transparent' }}>
                                            <TableCell sx={{ fontWeight: 'medium' }}>{brand}</TableCell>
                                            <TableCell align="right">{discountByBrand[brand]}%</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}

                {/* 🥧 Wykres kołowy - procentowy udział producenta */}
                {producerShare.length > 0 && (
                    <Box sx={{ mt: 4, mb: 4 }}>
                        <Typography variant="h6" gutterBottom>🥧 Procentowy podział zakupów według producentów</Typography>
                        <Paper sx={{ p: 3, borderRadius: '12px', height: 400 }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie 
                                        data={producerShare} 
                                        dataKey="value" 
                                        nameKey="name" 
                                        cx="50%" 
                                        cy="50%" 
                                        outerRadius={150}
                                        label={({ name, value }) => `${name}: ${value}%`}
                                    >
                                        {producerShare.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                        ))}
                                    </Pie>
                                    <Tooltip formatter={(value) => `${value}%`} />
                                    <Legend layout="vertical" verticalAlign="middle" align="right" />
                                </PieChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Box>
                )}
            </Box>
        </Container>
    );
};

export default OrderDataPage;