import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
    CircularProgress, Typography, Box, Grid, Paper, 
    Modal, Divider, Card, CardContent, Button
} from '@mui/material';
import axios from 'axios';
import { CompareArrows, ArrowBack, Visibility } from '@mui/icons-material';
import ActionButton from './ActionButton';
import PageHeader from './PageHeader';
import StylishTable from './StylishTable';

const IntegratedDocumentPage = () => {
    const { username, clientId, documentNumber } = useParams();
    const navigate = useNavigate();
    const [facturaDetails, setFacturaDetails] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const [comparisonData, setComparisonData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openImage, setOpenImage] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    
    // Format dla numerów dokumentów
    const formattedDocumentNumber = documentNumber ? documentNumber.replace(/-/g, "/").trim() : "";
    const decodedClientId = decodeURIComponent(clientId);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                
                console.log("Pobieranie danych dokumentu:", { 
                    username, 
                    clientId: decodedClientId, 
                    documentNumber: formattedDocumentNumber 
                });
                
                // Pobieranie danych faktury
                const facturaResponse = await axios.get(
                    `/api/invoice/${username}/${encodeURIComponent(decodedClientId)}/${encodeURIComponent(formattedDocumentNumber)}`
                ).catch(err => {
                    console.warn('Brak danych faktury:', err);
                    return { data: { products: [] } };
                });
                
                // Pobieranie danych zamówienia
                const orderResponse = await axios.get(
                    `/api/order/${username}/${encodeURIComponent(decodedClientId)}/${encodeURIComponent(formattedDocumentNumber)}`
                ).catch(err => {
                    console.warn('Brak danych zamówienia:', err);
                    return { data: { products: [] } };
                });
                
                // Walidacja danych faktury
                let facturaProducts = [];
                if (facturaResponse.data && Array.isArray(facturaResponse.data.products)) {
                    facturaProducts = facturaResponse.data.products.map(product => ({
                        ...product,
                        quantity: product.quantity || 0,
                        value: typeof product.value === 'number' ? product.value : 
                               parseFloat((product.value || "0").toString().replace(",", ".")) || 0,
                        discount: typeof product.discount === 'number' ? product.discount :
                                 parseFloat((product.discount || "0").toString().replace(",", ".")) || 0
                    }));
                }
                
                // Walidacja danych zamówienia
                let orderProducts = [];
                if (orderResponse.data && Array.isArray(orderResponse.data.products)) {
                    orderProducts = orderResponse.data.products.map(product => ({
                        ...product,
                        quantity: product.quantity || 0,
                        value: typeof product.value === 'number' ? product.value : 
                               parseFloat((product.value || "0").toString().replace(",", ".")) || 0,
                        discount: typeof product.discount === 'number' ? product.discount :
                                 parseFloat((product.discount || "0").toString().replace(",", ".")) || 0
                    }));
                }
                
                console.log("Dane faktury:", facturaProducts);
                console.log("Dane zamówienia:", orderProducts);
                
                // Zapisujemy szczegóły
                setFacturaDetails(facturaProducts);
                setOrderDetails(orderProducts);
                
                // Przygotowanie danych porównawczych
                prepareComparisonData(facturaProducts, orderProducts);
                
            } catch (error) {
                console.error('❌ Błąd pobierania danych:', error);
                setError('Nie udało się pobrać szczegółów dokumentów.');
            } finally {
                setLoading(false);
            }
        };
        
        fetchData();
    }, [username, clientId, documentNumber, formattedDocumentNumber, decodedClientId]);

    // Funkcja do przygotowania danych porównawczych
    const prepareComparisonData = (facturaProducts, orderProducts) => {
        // Tworzymy mapę wszystkich produktów
        const allProducts = new Map();
        
        // Dodajemy produkty z faktury
        facturaProducts.forEach(product => {
            allProducts.set(product.productName, {
                productName: product.productName,
                factura_quantity: product.quantity || 0,
                factura_value: product.value || 0,
                factura_discount: product.discount || 0,
                order_quantity: 0,
                order_value: 0,
                order_discount: 0,
                imageUrl: product.imageUrl
            });
        });
        
        // Dodajemy lub aktualizujemy produkty z zamówienia
        orderProducts.forEach(product => {
            if (allProducts.has(product.productName)) {
                // Aktualizacja istniejącego produktu
                const existingProduct = allProducts.get(product.productName);
                existingProduct.order_quantity = product.quantity || 0;
                existingProduct.order_value = product.value || 0;
                existingProduct.order_discount = product.discount || 0;
                existingProduct.imageUrl = existingProduct.imageUrl || product.imageUrl;
            } else {
                // Dodanie nowego produktu
                allProducts.set(product.productName, {
                    productName: product.productName,
                    factura_quantity: 0,
                    factura_value: 0,
                    factura_discount: 0,
                    order_quantity: product.quantity || 0,
                    order_value: product.value || 0,
                    order_discount: product.discount || 0,
                    imageUrl: product.imageUrl
                });
            }
        });
        
        // Obliczenie różnic i konwersja do tablicy
        const result = Array.from(allProducts.values()).map(product => ({
            ...product,
            quantity_diff: product.factura_quantity - product.order_quantity,
            value_diff: product.factura_value - product.order_value,
            discount_diff: product.factura_discount - product.order_discount
        }));
        
        // Sortowanie po nazwie produktu
        result.sort((a, b) => a.productName.localeCompare(b.productName));
        
        console.log("Dane porównawcze:", result);
        setComparisonData(result);
    };

    // Obsługa podglądu obrazu produktu
    const handleImageOpen = (imageUrl) => {
        if (imageUrl) {
            setImageSrc(imageUrl);
            setOpenImage(true);
        }
    };
    
    const handleImageClose = () => {
        setOpenImage(false);
    };
    
    // Obliczanie sum
    const calculateSummary = () => {
        if (comparisonData.length === 0) return {};
        
        const factura_quantity = comparisonData.reduce((sum, item) => sum + (item.factura_quantity || 0), 0);
        const order_quantity = comparisonData.reduce((sum, item) => sum + (item.order_quantity || 0), 0);
        const factura_value = comparisonData.reduce((sum, item) => sum + (item.factura_value || 0), 0);
        const order_value = comparisonData.reduce((sum, item) => sum + (item.order_value || 0), 0);
        
        // Obliczenie średnich rabatów
        let factura_discount_sum = 0;
        let factura_discount_count = 0;
        let order_discount_sum = 0;
        let order_discount_count = 0;
        
        comparisonData.forEach(item => {
            if (item.factura_quantity > 0) {
                factura_discount_sum += (item.factura_discount || 0);
                factura_discount_count++;
            }
            if (item.order_quantity > 0) {
                order_discount_sum += (item.order_discount || 0);
                order_discount_count++;
            }
        });
        
        const factura_discount = factura_discount_count > 0 
            ? (factura_discount_sum / factura_discount_count).toFixed(2) 
            : "0.00";
            
        const order_discount = order_discount_count > 0 
            ? (order_discount_sum / order_discount_count).toFixed(2) 
            : "0.00";
        
        console.log("Obliczone podsumowanie:", {
            factura_quantity,
            order_quantity,
            factura_value,
            order_value,
            factura_discount,
            order_discount
        });
        
        return {
            factura_quantity,
            order_quantity,
            quantity_diff: factura_quantity - order_quantity,
            factura_value,
            order_value,
            value_diff: factura_value - order_value,
            factura_discount,
            order_discount,
            discount_diff: (parseFloat(factura_discount) - parseFloat(order_discount)).toFixed(2)
        };
    };
    
    const summary = calculateSummary();
    
    return (
        <div style={{ padding: '16px', maxWidth: '1200px', margin: 'auto' }}>
            <PageHeader 
                title={`Porównanie dokumentu: ${formattedDocumentNumber}`} 
                subtitle={`Analiza różnic między fakturą a zamówieniem dla klienta: ${decodedClientId}`}
                icon={<CompareArrows />}
            />

            {loading && <CircularProgress style={{ display: 'block', margin: '40px auto' }} />}
            {error && <Typography color="error" style={{ textAlign: 'center', marginTop: '20px' }}>{error}</Typography>}

            {!loading && !error && (
                <>
                    {/* Podsumowanie dokumentów */}
                    <Paper sx={{ p: 3, mb: 4, borderRadius: '12px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" gutterBottom>Faktura</Typography>
                                <Typography>
                                    <strong>Liczba pozycji:</strong> {facturaDetails.length}
                                </Typography>
                                <Typography>
                                    <strong>Suma ilości:</strong> {summary.factura_quantity || 0}
                                </Typography>
                                <Typography>
                                    <strong>Wartość całkowita:</strong> {summary.factura_value?.toLocaleString('pl-PL', { minimumFractionDigits: 2 }) || '0.00'} zł
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" gutterBottom>Zamówienie</Typography>
                                <Typography>
                                    <strong>Liczba pozycji:</strong> {orderDetails.length}
                                </Typography>
                                <Typography>
                                    <strong>Suma ilości:</strong> {summary.order_quantity || 0}
                                </Typography>
                                <Typography>
                                    <strong>Wartość całkowita:</strong> {summary.order_value?.toLocaleString('pl-PL', { minimumFractionDigits: 2 }) || '0.00'} zł
                                </Typography>
                            </Grid>
                        </Grid>
                        
                        <Divider sx={{ my: 2 }} />
                        
                        <Typography variant="h6" gutterBottom>Różnice</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <Card 
                                    sx={{ 
                                        bgcolor: summary.quantity_diff < 0 ? '#ffebee' : 
                                              summary.quantity_diff > 0 ? '#e8f5e9' : '#f5f5f5',
                                        p: 2
                                    }}
                                >
                                    <CardContent>
                                        <Typography variant="subtitle1" gutterBottom>Różnica ilości</Typography>
                                        <Typography variant="h5" color={summary.quantity_diff < 0 ? 'error' : 
                                                            summary.quantity_diff > 0 ? 'success' : 'textPrimary'}>
                                            {summary.quantity_diff || 0}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Card 
                                    sx={{ 
                                        bgcolor: summary.value_diff < 0 ? '#ffebee' : 
                                              summary.value_diff > 0 ? '#e8f5e9' : '#f5f5f5',
                                        p: 2 
                                    }}
                                >
                                    <CardContent>
                                        <Typography variant="subtitle1" gutterBottom>Różnica wartości</Typography>
                                        <Typography variant="h5" color={summary.value_diff < 0 ? 'error' : 
                                                            summary.value_diff > 0 ? 'success' : 'textPrimary'}>
                                            {summary.value_diff?.toLocaleString('pl-PL', { minimumFractionDigits: 2 }) || '0.00'} zł
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Card 
                                    sx={{ 
                                        bgcolor: summary.discount_diff < 0 ? '#ffebee' : 
                                              summary.discount_diff > 0 ? '#e8f5e9' : '#f5f5f5',
                                        p: 2 
                                    }}
                                >
                                    <CardContent>
                                        <Typography variant="subtitle1" gutterBottom>Różnica rabatu</Typography>
                                        <Typography variant="h5" color={summary.discount_diff < 0 ? 'error' : 
                                                            summary.discount_diff > 0 ? 'success' : 'textPrimary'}>
                                            {summary.discount_diff || 0}%
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Paper>

                    {/* Tabela porównawcza */}
                    {comparisonData.length > 0 && (
                        <Box sx={{ mb: 4, overflowX: 'auto' }}>
                            <Typography variant="h6" gutterBottom>Porównanie pozycji</Typography>
                            <Box sx={{ minWidth: { xs: '1000px', md: '100%' } }}>
                                <StylishTable
                                    columns={[
                                        { id: 'productName', label: 'Produkt', format: (value, row) => (
                                            <Box 
                                                sx={{ 
                                                    cursor: row.imageUrl ? 'pointer' : 'default',
                                                    color: row.imageUrl ? '#1976d2' : 'inherit',
                                                    fontWeight: 'bold',
                                                    textDecoration: row.imageUrl ? 'underline' : 'none'
                                                }}
                                                onClick={() => row.imageUrl && handleImageOpen(row.imageUrl)}
                                            >
                                                {value}
                                                {row.imageUrl && (
                                                    <Visibility 
                                                        fontSize="small" 
                                                        sx={{ ml: 1, verticalAlign: 'middle' }} 
                                                    />
                                                )}
                                            </Box>
                                        )},
                                        { id: 'factura_quantity', label: 'Ilość (Faktura)', align: 'right' },
                                        { id: 'order_quantity', label: 'Ilość (Zamówienie)', align: 'right' },
                                        { id: 'quantity_diff', label: 'Różnica ilości', align: 'right', 
                                          format: (value) => {
                                            const color = value < 0 ? 'red' : value > 0 ? 'green' : 'inherit';
                                            return <span style={{ color, fontWeight: 'bold' }}>{value}</span>;
                                          } 
                                        },
                                        { id: 'factura_value', label: 'Wartość (Faktura)', align: 'right',
                                          format: (value) => value.toLocaleString('pl-PL', { minimumFractionDigits: 2 }) + ' zł'
                                        },
                                        { id: 'order_value', label: 'Wartość (Zamówienie)', align: 'right',
                                          format: (value) => value.toLocaleString('pl-PL', { minimumFractionDigits: 2 }) + ' zł'
                                        },
                                        { id: 'value_diff', label: 'Różnica wartości', align: 'right',
                                          format: (value) => {
                                            const color = value < 0 ? 'red' : value > 0 ? 'green' : 'inherit';
                                            return <span style={{ color, fontWeight: 'bold' }}>
                                                {value.toLocaleString('pl-PL', { minimumFractionDigits: 2 })} zł
                                            </span>;
                                          }
                                        },
                                        { id: 'factura_discount', label: 'Rabat (Faktura)', align: 'right',
                                          format: (value) => `${value}%`
                                        },
                                        { id: 'order_discount', label: 'Rabat (Zamówienie)', align: 'right',
                                          format: (value) => `${value}%`
                                        },
                                    ]}
                                    data={comparisonData}
                                    summary={{
                                        productName: 'Podsumowanie',
                                        factura_quantity: summary.factura_quantity,
                                        order_quantity: summary.order_quantity,
                                        quantity_diff: summary.quantity_diff,
                                        factura_value: summary.factura_value,
                                        order_value: summary.order_value,
                                        value_diff: summary.value_diff,
                                        factura_discount: summary.factura_discount + '%',
                                        order_discount: summary.order_discount + '%'
                                    }}
                                />
                            </Box>
                        </Box>
                    )}

                    {/* Przyciski nawigacyjne */}
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mt: 4 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate(`/integrated-profile/${username}/${clientId}`)}
                            startIcon={<ArrowBack />}
                        >
                            Wróć do porównania
                        </Button>
                    </Box>
                    
                    {/* Modal z obrazkiem produktu */}
                    <Modal open={openImage && imageSrc} onClose={handleImageClose}>
                        <Box sx={{ 
                            position: 'absolute', 
                            top: '50%', 
                            left: '50%', 
                            transform: 'translate(-50%, -50%)', 
                            bgcolor: 'background.paper', 
                            boxShadow: 24, 
                            p: 4,
                            borderRadius: 2,
                            maxWidth: '90%',
                            maxHeight: '90%',
                            overflow: 'auto'
                        }}>
                            {imageSrc ? (
                                <img 
                                    src={imageSrc} 
                                    alt="Zdjęcie produktu" 
                                    style={{ maxWidth: '100%', maxHeight: '80vh' }} 
                                />
                            ) : (
                                <Typography>Brak dostępnego zdjęcia</Typography>
                            )}
                        </Box>
                    </Modal>
                </>
            )}
        </div>
    );
};

export default IntegratedDocumentPage;