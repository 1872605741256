import React, { useState, useEffect } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from '@mui/material';
import axios from 'axios';

const RecentLoginsPage = () => {
    const [logins, setLogins] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchLogins = async () => {
            setLoading(true);
            setError('');
            try {
                const response = await axios.get('/api/auth/recent-logins');
                setLogins(response.data);
            } catch (error) {
                console.error('Błąd pobierania logowań:', error);
                setError('Nie udało się pobrać logowań.');
            } finally {
                setLoading(false);
            }
        };
        fetchLogins();
    }, []);

    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
            <Typography variant="h4" gutterBottom>Ostatnie Logowania</Typography>
            {loading && <CircularProgress style={{ marginTop: '20px' }} />}
            {error && <Typography color="error" style={{ marginTop: '20px' }}>{error}</Typography>}
            {logins.length > 0 && (
                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell align="right">Data logowania</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {logins.map((login, index) => (
                                <TableRow key={index}>
                                    <TableCell>{login.email}</TableCell>
                                    <TableCell align="right">{new Date(login.loginTime).toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

export default RecentLoginsPage;
