import React from 'react';
import Form from './Form';
import { Box, useMediaQuery } from '@mui/material';
import PageHeader from './PageHeader';
import { Receipt } from '@mui/icons-material';

function FormPage() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        padding: { xs: '10px', sm: '20px' },
        position: 'relative',
        overflow: 'hidden',
        minHeight: '100%',
      }}
    >
      {/* Ozdobne tło */}
      <Box 
        sx={{
          position: 'fixed',
          top: { xs: -100, md: -200 },
          right: { xs: -100, md: -200 },
          width: { xs: 300, md: 500 },
          height: { xs: 300, md: 500 },
          borderRadius: '50%',
          background: 'radial-gradient(circle, rgba(33,150,243,0.1) 0%, rgba(33,150,243,0) 70%)',
          zIndex: -1
        }}
      />
      
      <Box 
        sx={{
          position: 'fixed',
          bottom: { xs: -100, md: -200 },
          left: { xs: -100, md: -200 },
          width: { xs: 300, md: 500 },
          height: { xs: 300, md: 500 },
          borderRadius: '50%',
          background: 'radial-gradient(circle, rgba(76,175,80,0.1) 0%, rgba(76,175,80,0) 70%)',
          zIndex: -1
        }}
      />
      
      {/* Header strony */}
      <PageHeader
        title="Formularz zwrotu"
        subtitle="Wprowadź szczegóły wydatku i załącz skan faktury do rozliczenia"
        icon={<Receipt />}
      />
      
      {/* Główna zawartość formularza */}
      <Box
        sx={{
          maxWidth: { xs: '100%', sm: '600px' },
          margin: '0 auto',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Form />
      </Box>
    </Box>
  );
}

export default FormPage;