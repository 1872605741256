import React from 'react'; 
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Chip, Box, useMediaQuery, IconButton, Collapse,
  Typography
} from '@mui/material'; 
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const StylishTable = ({ columns, data, summary, onRowClick }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));
  
  // Na urządzeniach mobilnych używamy komponentu MobileTableRow
  // Dla desktopa używamy standardowej tabeli
  if (isMobile) {
    return (
      <MobileTableView 
        columns={columns}
        data={data}
        summary={summary}
        onRowClick={onRowClick}
      />
    );
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: { xs: '12px', sm: '14px', md: '16px' },
        overflow: 'hidden',
        boxShadow: '0 10px 30px rgba(0,0,0,0.08)',
      }}
    >
      <Table>
        <TableHead>
          <TableRow sx={{ 
            backgroundColor: (theme) => theme.palette.mode === 'dark'
              ? 'rgba(255,255,255,0.05)'
              : 'rgba(0,0,0,0.02)'
          }}>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align || 'left'}
                sx={{
                  fontWeight: 700,
                  py: isTablet ? 2 : 2.5,
                  px: isTablet ? 1.5 : 2,
                  borderBottom: (theme) => `2px solid ${theme.palette.primary.main}`,
                  color: (theme) => theme.palette.mode === 'dark' ? 'white' : 'inherit',
                  fontSize: isTablet ? '0.8125rem' : '0.875rem'
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={index}
              sx={{ 
                cursor: onRowClick ? 'pointer' : 'default',
                opacity: 1,
                transform: 'translateY(0)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.04)',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.05)'
                }
              }}
              onClick={() => onRowClick && onRowClick(row)}
            >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align || 'left'}
                  sx={{
                    py: isTablet ? 1.8 : 2.5,
                    px: isTablet ? 1.5 : 2,
                    borderBottom: '1px solid rgba(128,128,128,0.1)',
                    '&:first-of-type': {
                      pl: 3
                    },
                    fontSize: isTablet ? '0.8125rem' : '0.875rem'
                  }}
                >
                  {column.format ? column.format(row[column.id], row) : (
                    // Specjalne formatowanie dla rabatu
                    column.id === 'discount' ? (
                      <Chip 
                        label={`${row[column.id]}%`}
                        size="small"
                        color={parseFloat(String(row[column.id]).replace(',', '.')) > 10 ? "error" : "primary"}
                        variant="outlined"
                        sx={{
                          fontWeight: 500,
                          borderRadius: '12px'
                        }}
                      />
                    ) : row[column.id]
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
          
          {summary && (
            <TableRow sx={{
              backgroundColor: (theme) => theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,0.05)'
                : 'rgba(0,0,0,0.02)'
            }}>
              {columns.map((column, idx) => (
                <TableCell
                  key={idx}
                  align={column.align || 'left'}
                  sx={{
                    fontWeight: 700,
                    py: isTablet ? 2 : 2.5,
                    px: isTablet ? 1.5 : 2,
                    borderTop: (theme) => `2px solid ${theme.palette.primary.main}`,
                    color: (theme) => theme.palette.mode === 'dark' 
                      ? theme.palette.primary.light
                      : theme.palette.primary.dark,
                    fontSize: isTablet ? '0.8125rem' : '0.875rem'
                  }}
                >
                  {summary[column.id] !== undefined ? (
                    typeof summary[column.id] === 'string' ? 
                      summary[column.id] : 
                      column.format ? 
                        column.format(summary[column.id]) : 
                        summary[column.id]
                  ) : (
                    idx === 0 ? (
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        fontWeight: 'bold',
                      }}>
                        Podsumowanie
                      </Box>
                    ) : ''
                  )}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// Komponent wiersza dla widoku mobilnego z możliwością rozwijania
const MobileTableRow = ({ row, columns, index }) => {
  const [open, setOpen] = React.useState(false);
  
  // Identyfikujemy najważniejsze kolumny do wyświetlenia w głównym wierszu
  // Zakładamy, że pierwsza kolumna to zawsze data/numer
  const primaryColumn = columns[0];
  
  // Szukamy kolumny wartości (zwykle zawiera 'value' w id)
  const valueColumn = columns.find(col => 
    col.id.toLowerCase().includes('value') || 
    col.id.toLowerCase().includes('amount') || 
    col.id.toLowerCase().includes('kwota')
  ) || columns[columns.length - 2]; // Fallback do przedostatniej kolumny
  
  return (
    <>
      <TableRow 
        sx={{ 
          '& > *': { borderBottom: 'unset' },
          cursor: 'pointer',
          backgroundColor: index % 2 === 0 ? 'rgba(0,0,0,0.01)' : 'transparent'
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell sx={{ px: 2, py: 1.5 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box>
              {/* Główna wartość (np. data lub numer faktury) */}
              <Typography variant="subtitle2" sx={{ fontWeight: 'medium' }}>
                {primaryColumn.format 
                  ? primaryColumn.format(row[primaryColumn.id], row) 
                  : row[primaryColumn.id]}
              </Typography>
              
              {/* Kwota - druga najważniejsza informacja */}
              <Typography variant="body2" color="text.secondary">
                {valueColumn.format 
                  ? valueColumn.format(row[valueColumn.id], row) 
                  : `${row[valueColumn.id]} ${valueColumn.unit || 'zł'}`}
              </Typography>
            </Box>
            
            <IconButton size="small">
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      
      <TableRow>
        <TableCell sx={{ py: 0, px: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ py: 2, px: 3 }}>
              {columns.map((column, idx) => (
                // Pomijamy kolumny już wyświetlone w głównym wierszu
                column.id !== primaryColumn.id && (
                  <Box key={column.id} sx={{ display: 'flex', my: 1 }}>
                    <Typography variant="body2" sx={{ width: '40%', fontWeight: 'medium', color: 'text.secondary' }}>
                      {column.label}:
                    </Typography>
                    <Typography variant="body2" sx={{ width: '60%' }}>
                      {column.format
                        ? column.format(row[column.id], row)
                        : row[column.id]
                      }
                    </Typography>
                  </Box>
                )
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

// Widok mobilny tabeli - wykorzystuje karty rozwijane
const MobileTableView = ({ columns, data, summary, onRowClick }) => {
  return (
    <TableContainer 
      component={Paper}
      sx={{
        borderRadius: '12px',
        overflow: 'hidden',
        boxShadow: '0 8px 20px rgba(0,0,0,0.08)',
      }}
    >
      <Table>
        <TableBody>
          {data.map((row, index) => (
            <MobileTableRow 
              key={index} 
              row={row} 
              columns={columns} 
              index={index}
            />
          ))}
          
          {summary && (
            <TableRow 
              sx={{ 
                backgroundColor: (theme) => theme.palette.mode === 'dark'
                  ? 'rgba(255,255,255,0.05)'
                  : 'rgba(0,0,0,0.02)',
                borderTop: (theme) => `2px solid ${theme.palette.primary.main}`
              }}
            >
              <TableCell sx={{ py: 2, px: 3 }}>
                <Typography fontWeight="bold" variant="subtitle1">Podsumowanie</Typography>
                
                <Box sx={{ mt: 1.5 }}>
                  {columns.map((column, idx) => 
                    summary[column.id] !== undefined && column.id !== columns[0].id && (
                      <Box key={column.id} sx={{ display: 'flex', my: 0.75, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ width: '40%', fontWeight: 'medium', color: 'text.secondary' }}>
                          {column.label}:
                        </Typography>
                        <Typography variant="body2" sx={{ width: '60%', fontWeight: 'bold' }}>
                          {typeof summary[column.id] === 'string' 
                            ? summary[column.id] 
                            : column.format 
                              ? column.format(summary[column.id]) 
                              : summary[column.id]
                          }
                        </Typography>
                      </Box>
                    )
                  )}
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StylishTable;