import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
    CircularProgress, Typography, Button, Modal
} from '@mui/material';
import axios from 'axios';
import StylishTable from './StylishTable';

const OrderPage = () => {
    const { username, clientId, orderNumber } = useParams();
    const navigate = useNavigate();

    // ✅ Debugowanie - logujemy wszystko, co dostajemy z useParams()
    console.log("🔍 useParams() zwrócił:", { username, clientId, orderNumber });

    const formattedOrderNumber = orderNumber ? orderNumber.replace(/-/g, "/").trim() : "";
    // const normalizedClientName = clientId ? decodeURIComponent(clientId).trim() : "";
    const formattedClientName = clientId ? clientId.trim() : "";

    const [orderDetails, setOrderDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openImage, setOpenImage] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        if (!username || !clientId || !orderNumber) {
            setError("❌ Brak wymaganych parametrów zamówienia.");
            setLoading(false);
            return;
        }

        const fetchOrderDetails = async () => {
            try {
                console.log("📥 Pobieranie zamówienia:", formattedOrderNumber, "dla klienta:", formattedClientName);

                const response = await axios.get(`/api/order/${username}/${encodeURIComponent(formattedClientName)}/${encodeURIComponent(formattedOrderNumber)}`);

                if (response.data && response.data.products && Array.isArray(response.data.products)) {
                    setOrderDetails(response.data.products.sort((a, b) => a.productName.localeCompare(b.productName)));
                } else {
                    setError('Brak produktów na tym zamówieniu.');
                }
            } catch (error) {
                console.error('❌ Błąd pobierania danych zamówienia:', error);
                setError('Nie udało się pobrać danych zamówienia.');
            } finally {
                setLoading(false);
            }
        };

        fetchOrderDetails();
    }, [username, formattedClientName, formattedOrderNumber, clientId, orderNumber]);

    const handleImageOpen = (imageUrl) => {
        if (imageUrl) {
            setImageSrc(imageUrl);
            setOpenImage(true);
        }
    };

    const handleImageClose = () => {
        setOpenImage(false);
    };

    const totalQuantity = orderDetails.reduce((sum, item) => sum + item.quantity, 0);
    const totalValue = orderDetails.reduce((sum, item) => sum + item.value, 0);
    const totalDiscounts = orderDetails.reduce((sum, item) => sum + item.discount, 0);
    const averageDiscount = orderDetails.length > 0 
        ? (totalDiscounts / orderDetails.length).toFixed(2) 
        : "0.00";

    return (
        <div style={{ padding: '20px', maxWidth: '1000px', margin: 'auto' }}>
            <Typography variant="h4" gutterBottom>
                Zamówienie: {formattedOrderNumber}
            </Typography>

            {error && (
                <Typography color="error" style={{ textAlign: 'center', padding: '20px' }}>
                    {error}
                </Typography>
            )}

            {loading && <CircularProgress />}

            {!loading && !error && orderDetails.length > 0 && (
                <>
                    <StylishTable
                        columns={[
                            { id: 'productName', label: 'Produkt', format: (value, row) => (
                                <span 
                                    style={{ 
                                        cursor: 'pointer', 
                                        color: '#1976d2', 
                                        fontWeight: 'bold', 
                                        textDecoration: 'underline' 
                                    }}
                                    onClick={() => handleImageOpen(row.imageUrl)}
                                >
                                    {value}
                                </span>
                            )},
                            { id: 'quantity', label: 'Ilość zamówiona', align: 'right' },
                            { id: 'value', label: 'Wartość zamówienia (zł)', align: 'right', format: (value) => 
                                `${value.toLocaleString('pl-PL', { minimumFractionDigits: 2 })} zł` 
                            },
                            { id: 'discount', label: 'Rabat (%)', align: 'right', format: (value) => `${value}%` },
                        ]}
                        data={orderDetails}
                        summary={{
                            productName: 'Podsumowanie',
                            quantity: totalQuantity,
                            value: `${totalValue.toLocaleString('pl-PL', { minimumFractionDigits: 2 })} zł`,
                            discount: `${averageDiscount}%`
                        }}
                    />

                    {/* Przycisk powrotu */}
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={() => navigate(`/order-profile/${username}/${clientId}`)}                            
                            style={{ fontSize: '16px', padding: '10px 20px' }}
                        >
                            Wróć do podsumowania klienta
                        </Button>
                    </div>
                    
                    {/* MODAL z obrazkiem produktu */}
                    <Modal open={openImage && imageSrc} onClose={handleImageClose}>
                        <div style={{ 
                            position: 'absolute', 
                            top: '50%', 
                            left: '50%', 
                            transform: 'translate(-50%, -50%)', 
                            backgroundColor: 'white', 
                            padding: '20px', 
                            borderRadius: '10px', 
                            textAlign: 'center',
                            boxShadow: '0px 4px 6px rgba(0,0,0,0.1)'
                        }}>
                            {imageSrc ? <img src={imageSrc} alt="Zdjęcie produktu" style={{ maxWidth: '100%', maxHeight: '500px' }} /> : <p>Brak zdjęcia</p>}
                        </div>
                    </Modal>
                </>
            )}
        </div>
    );
};

export default OrderPage;