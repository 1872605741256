import React, { useState, useEffect } from 'react';
import { 
    CircularProgress, Typography, Box, Grid, Paper, Autocomplete, TextField, Button
} from '@mui/material';
import axios from 'axios';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Link } from 'react-router-dom';
import ActionButton from './ActionButton';
import PageHeader from './PageHeader';
import { CompareArrows, BarChartOutlined, ShoppingCartOutlined } from '@mui/icons-material';
import StylishTable from './StylishTable';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28BFF', '#FF6384', '#36A2EB'];

const IntegratedDataPage = () => {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [facturaData, setFacturaData] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [producerShareFactura, setProducerShareFactura] = useState([]);
    const [producerShareOrder, setProducerShareOrder] = useState([]);
    const [facturasByYear, setFacturasByYear] = useState({});
    const [ordersByYear, setOrdersByYear] = useState({});
    const [yearList, setYearList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    const username = user ? user.email.split('@')[0] : null;

    // Pobieranie listy klientów
    useEffect(() => {
        const fetchClients = async () => {
            try {
                if (!username) {
                    throw new Error('Brak nazwy użytkownika.');
                }
                setLoading(true);
                const headers = token ? { Authorization: `Bearer ${token}` } : {};
                
                // Pobieramy klientów z obu źródeł - faktur i zamówień
                const [facturaClientsResponse, orderClientsResponse] = await Promise.all([
                    axios.get(`/api/clients?username=${username}`, { headers }),
                    axios.get(`/api/order-clients?username=${username}`, { headers })
                ]);
                
                // Usuwamy nagłówki, jeśli istnieją
                let facturaClients = facturaClientsResponse.data;
                if (facturaClients.length > 0 && facturaClients[0] === "Kontrahent Nazwa") {
                    facturaClients.shift();
                }
                
                let orderClients = orderClientsResponse.data;
                if (orderClients.length > 0 && orderClients[0] === "Kontrahent Nazwa") {
                    orderClients.shift();
                }
                
                // Łączymy klientów z obu źródeł i usuwamy duplikaty
                const allClients = [...new Set([...facturaClients, ...orderClients])];
                setClients(allClients);
            } catch (error) {
                console.error('Błąd podczas pobierania listy klientów:', error);
                setError('Nie udało się pobrać listy klientów.');
            } finally {
                setLoading(false);
            }
        };
        
        fetchClients();
    }, [token, username]);

    // Obsługa wyboru klienta
    const handleSelectClient = async (_, client) => {
        if (!client) return;
        
        setSelectedClient(client);
        setFacturaData([]); 
        setOrderData([]);
        setLoading(true);
        setError('');

        try {
            if (!username) {
                throw new Error('Brak nazwy użytkownika.');
            }
            
            const headers = token ? { Authorization: `Bearer ${token}` } : {};
            
            // Równoległe pobieranie danych faktur i zamówień
            const [facturaResponse, orderResponse] = await Promise.all([
                axios.get(`/api/client-data/${username}`, { headers }),
                axios.get(`/api/order-data/${username}/${encodeURIComponent(client)}`, { headers }).catch(err => {
                    console.warn('Brak danych zamówień dla klienta:', err);
                    return { data: { purchases: [] } };
                })
            ]);
            
            // Filtrowanie danych faktur dla wybranego klienta
            const filteredFacturaData = facturaResponse.data.filter(entry => entry['Kontrahent Nazwa'] === client);
            setFacturaData(filteredFacturaData);
            
            // Ustawienie danych zamówień
            const orderPurchases = orderResponse.data.purchases || [];
            setOrderData(orderPurchases);
            
            // Przetwarzanie danych
            processData(filteredFacturaData, orderPurchases);
            
        } catch (error) {
            console.error('Błąd podczas pobierania danych klienta:', error);
            setError('Nie udało się pobrać danych klienta.');
        } finally {
            setLoading(false);
        }
    };

    // Funkcja przetwarzająca dane do wykresów i tabel
    const processData = (facturaData, orderData) => {
        // Przygotowanie danych dla faktur
        const facturaByYear = {};
        const facturaYears = new Set();
        const facturaProducerCounts = {};

        // Śledź unikalne faktury
        const uniqueFacturaNumbers = new Set();

        facturaData.forEach(item => {
            // Dodajemy numer faktury do zbioru unikalnych numerów
            if (item["Dokument Numer"]) {
                uniqueFacturaNumbers.add(item["Dokument Numer"]);
            }

            const brand = item['Produkt Marka'];
            const producer = item['Produkt Producent'];
            if (!brand || !producer) return;

            let dateStr = item['Data Wystawienia Dzień'];
            if (!dateStr || typeof dateStr !== "string") return;

            const year = dateStr.split("-")[0]; 
            const parsedYear = parseInt(year, 10);
            if (isNaN(parsedYear) || parsedYear < 2000 || parsedYear > new Date().getFullYear() + 1) {
                return;
            }

            let quantity = parseInt(item['Sprzedaż Ilość'], 10);
            if (isNaN(quantity)) {
                quantity = 0;
            }

            // Tabela sprzedaży na lata i marki
            if (!facturaByYear[brand]) {
                facturaByYear[brand] = {};
            }
            if (!facturaByYear[brand][parsedYear]) {
                facturaByYear[brand][parsedYear] = 0;
            }
            facturaByYear[brand][parsedYear] += quantity;
            facturaYears.add(parsedYear);

            // Wykres kołowy - procentowy udział producenta
            if (!facturaProducerCounts[producer]) {
                facturaProducerCounts[producer] = 0;
            }
            facturaProducerCounts[producer] += quantity;
        });

        // Przygotowanie danych dla zamówień
        const orderByYear = {};
        const orderYears = new Set();
        const orderProducerCounts = {};

        // Śledź unikalne zamówienia
        const uniqueOrderNumbers = new Set();

        orderData.forEach(item => {
            // Dodajemy numer zamówienia do zbioru unikalnych numerów
            if (item["Dokument Numer"]) {
                uniqueOrderNumbers.add(item["Dokument Numer"]);
            }

            const brand = item['Produkt Marka'];
            const producer = item['Produkt Producent'];
            if (!brand || !producer) return;

            let dateStr = item['Data Wystawienia Dzień'];
            if (!dateStr || typeof dateStr !== "string") return;

            const year = dateStr.split("-")[0]; 
            const parsedYear = parseInt(year, 10);
            if (isNaN(parsedYear) || parsedYear < 2000 || parsedYear > new Date().getFullYear() + 1) {
                return;
            }

            let quantity = parseInt(item['Sprzedaż Ilość'], 10);
            if (isNaN(quantity)) {
                quantity = 0;
            }

            // Tabela zamówień na lata i marki
            if (!orderByYear[brand]) {
                orderByYear[brand] = {};
            }
            if (!orderByYear[brand][parsedYear]) {
                orderByYear[brand][parsedYear] = 0;
            }
            orderByYear[brand][parsedYear] += quantity;
            orderYears.add(parsedYear);

            // Wykres kołowy - procentowy udział producenta
            if (!orderProducerCounts[producer]) {
                orderProducerCounts[producer] = 0;
            }
            orderProducerCounts[producer] += quantity;
        });

        console.log("Unikalne numery faktur:", uniqueFacturaNumbers.size);
        console.log("Unikalne numery zamówień:", uniqueOrderNumbers.size);

        // Połączenie lat z obu źródeł danych
        const allYears = [...new Set([...facturaYears, ...orderYears])].sort();

        // Aktualizacja stanów
        setFacturasByYear(facturaByYear);
        setOrdersByYear(orderByYear);
        setYearList(allYears);

        // Przygotowanie danych do wykresów kołowych
        prepareChartData(facturaProducerCounts, orderProducerCounts);
    };

    // Przygotowanie danych do wykresów kołowych
    const prepareChartData = (facturaProducerCounts, orderProducerCounts) => {
        // Utwórz mapę wszystkich unikalnych producentów
        const allProducers = new Set([
            ...Object.keys(facturaProducerCounts),
            ...Object.keys(orderProducerCounts)
        ]);
        
        // Utwórz mapowanie producentów do kolorów
        const producerColorMap = {};
        Array.from(allProducers).forEach((producer, index) => {
            producerColorMap[producer] = COLORS[index % COLORS.length];
        });
        
        // Wykres kołowy dla faktur
        const totalFacturaQuantity = Object.values(facturaProducerCounts).reduce((acc, val) => acc + val, 0);
        const facturaData = Object.keys(facturaProducerCounts).map((producer) => ({
            name: producer,
            value: Math.round((facturaProducerCounts[producer] / totalFacturaQuantity) * 100),
            // Używamy koloru z mapy
            color: producerColorMap[producer]
        }));
        setProducerShareFactura(facturaData);

        // Wykres kołowy dla zamówień
        const totalOrderQuantity = Object.values(orderProducerCounts).reduce((acc, val) => acc + val, 0);
        const orderData = Object.keys(orderProducerCounts).map((producer) => ({
            name: producer,
            value: Math.round((orderProducerCounts[producer] / totalOrderQuantity) * 100),
            // Używamy koloru z mapy - ten sam producent będzie miał ten sam kolor co na pierwszym wykresie
            color: producerColorMap[producer]
        }));
        setProducerShareOrder(orderData);
    };

    // Przygotowanie danych do tabeli porównawczej
    const prepareComparisonTable = () => {
        if (!yearList.length) return [];

        // Zbieramy wszystkie marki z obu źródeł
        const allBrands = [...new Set([
            ...Object.keys(facturasByYear),
            ...Object.keys(ordersByYear)
        ])];

        // Tworzymy wiersze tabeli porównawczej
        return allBrands.map(brand => {
            const row = { brand };

            yearList.forEach(year => {
                row[`factura_${year}`] = facturasByYear[brand]?.[year] || 0;
                row[`order_${year}`] = ordersByYear[brand]?.[year] || 0;
                row[`diff_${year}`] = (facturasByYear[brand]?.[year] || 0) - (ordersByYear[brand]?.[year] || 0);
            });

            return row;
        });
    };

    // Kolumny do tabeli porównawczej
    const getComparisonColumns = () => {
        const columns = [
            { id: 'brand', label: 'Marka' }
        ];

        yearList.forEach(year => {
            columns.push({ 
                id: `factura_${year}`, 
                label: `Faktury ${year}`, 
                align: 'right' 
            });
            columns.push({ 
                id: `order_${year}`, 
                label: `Zamówienia ${year}`, 
                align: 'right' 
            });
            columns.push({ 
                id: `diff_${year}`, 
                label: `Różnica ${year}`, 
                align: 'right',
                format: (value) => {
                    const color = value < 0 ? 'red' : value > 0 ? 'green' : 'inherit';
                    return <span style={{ color }}>{value}</span>;
                }
            });
        });

        return columns;
    };

    const comparisonTableData = prepareComparisonTable();
    const comparisonColumns = getComparisonColumns();

    return (
        <div style={{ padding: '16px', maxWidth: '1200px', margin: 'auto' }}>
            <PageHeader
                title="Porównanie Faktur i Zamówień"
                subtitle="Analiza porównawcza danych z systemu faktur i systemu zamówień"
                icon={<CompareArrows />}
            />

            <Autocomplete
                options={clients}
                value={selectedClient}
                onChange={handleSelectClient}
                renderInput={(params) => (
                    <TextField {...params} label="Wybierz klienta" variant="outlined" fullWidth />
                )}
                style={{ marginBottom: '20px' }}
            />

            {selectedClient && (
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', md: 'row' }, 
                    justifyContent: 'space-between', 
                    gap: 2, 
                    mb: 4 
                }}>
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={`/client-profile/${username}/${selectedClient}`}
                        startIcon={<BarChartOutlined />}
                        fullWidth
                        sx={{ mb: { xs: 1, md: 0 } }}
                    >
                        Historia faktur
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={`/order-profile/${username}/${encodeURIComponent(selectedClient)}`}
                        startIcon={<ShoppingCartOutlined />}
                        fullWidth
                        sx={{ mb: { xs: 1, md: 0 } }}
                    >
                        Historia zamówień
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        component={Link}
                        to={`/integrated-profile/${username}/${encodeURIComponent(selectedClient)}`}
                        startIcon={<CompareArrows />}
                        fullWidth
                    >
                        Porównaj historie
                    </Button>
                </Box>
            )}

            {loading && <CircularProgress style={{ display: 'block', margin: '40px auto' }} />}
            {error && <Typography color="error" style={{ textAlign: 'center', marginTop: '20px' }}>{error}</Typography>}

            {facturaData.length > 0 || orderData.length > 0 ? (
                <>
                    {/* Podsumowanie danych */}
                    <Paper sx={{ p: 3, mb: 4, borderRadius: '16px' }}>
                        <Typography variant="h6" gutterBottom>📊 Podsumowanie danych klienta</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" fontWeight="bold">System faktur</Typography>
                                <Typography>Liczba unikalnych faktur: {new Set(facturaData.map(item => item["Dokument Numer"])).size}</Typography>
                                <Typography>Liczba pozycji na fakturach: {facturaData.length}</Typography>
                                <Typography>Marki: {Object.keys(facturasByYear).length}</Typography>
                                {/* Obliczamy całkowitą wartość faktur */}
                                <Typography>
                                    Wartość: {facturaData.reduce((sum, item) => {
                                        const value = parseFloat((item["Sprzedaż Wartość"] || "0").toString().replace(",", ".")) || 0;
                                        return sum + value;
                                    }, 0).toLocaleString('pl-PL', { minimumFractionDigits: 2 })} zł
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" fontWeight="bold">System zamówień</Typography>
                                <Typography>Liczba unikalnych zamówień: {new Set(orderData.map(item => item["Dokument Numer"])).size}</Typography>
                                <Typography>Liczba pozycji na zamówieniach: {orderData.length}</Typography>
                                <Typography>Marki: {Object.keys(ordersByYear).length}</Typography>
                                {/* Obliczamy całkowitą wartość zamówień */}
                                <Typography>
                                    Wartość: {orderData.reduce((sum, item) => {
                                        const value = parseFloat((item["Sprzedaż Wartość"] || "0").toString().replace(",", ".")) || 0;
                                        return sum + value;
                                    }, 0).toLocaleString('pl-PL', { minimumFractionDigits: 2 })} zł
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>

                    {/* Wykresy kołowe - porównanie udziału producentów */}
                    {(producerShareFactura.length > 0 || producerShareOrder.length > 0) && (
                        <Grid container spacing={3} sx={{ mb: 4 }}>
                            <Grid item xs={12}>
                                <Typography variant="h6">🥧 Porównanie udziału producentów</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Paper sx={{ p: 2, height: '100%' }}>
                                    <Typography variant="subtitle1" textAlign="center" gutterBottom>
                                        System faktur
                                    </Typography>
                                    {producerShareFactura.length > 0 ? (
                                        <Box sx={{ height: { xs: '250px', md: '300px' }, width: '100%' }}>
                                            <ResponsiveContainer width="100%" height="100%">
                                                <PieChart>
                                                    <Pie 
                                                        data={producerShareFactura} 
                                                        dataKey="value" 
                                                        nameKey="name" 
                                                        cx="50%" 
                                                        cy="50%" 
                                                        outerRadius="70%" 
                                                        label={({ value }) => `${value}%`}
                                                    >
                                                        {producerShareFactura.map((entry, index) => (
                                                            <Cell key={`cell-factura-${index}`} fill={entry.color} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip />
                                                    <Legend layout="horizontal" verticalAlign="bottom" align="center" />
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </Box>
                                    ) : (
                                        <Typography textAlign="center" sx={{ mt: 10, color: 'text.secondary' }}>
                                            Brak danych do wyświetlenia
                                        </Typography>
                                    )}
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Paper sx={{ p: 2, height: '100%' }}>
                                    <Typography variant="subtitle1" textAlign="center" gutterBottom>
                                        System zamówień
                                    </Typography>
                                    {producerShareOrder.length > 0 ? (
                                        <Box sx={{ height: { xs: '250px', md: '300px' }, width: '100%' }}>
                                            <ResponsiveContainer width="100%" height="100%">
                                                <PieChart>
                                                    <Pie 
                                                        data={producerShareOrder} 
                                                        dataKey="value" 
                                                        nameKey="name" 
                                                        cx="50%" 
                                                        cy="50%" 
                                                        outerRadius="70%" 
                                                        label={({ value }) => `${value}%`}
                                                    >
                                                        {producerShareOrder.map((entry, index) => (
                                                            <Cell key={`cell-order-${index}`} fill={entry.color} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip />
                                                    <Legend layout="horizontal" verticalAlign="bottom" align="center" />
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </Box>
                                    ) : (
                                        <Typography textAlign="center" sx={{ mt: 10, color: 'text.secondary' }}>
                                            Brak danych do wyświetlenia
                                        </Typography>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    )}

                    {/* Tabela porównawcza */}
                    {comparisonTableData.length > 0 && (
                        <Box sx={{ mb: 4, overflowX: 'auto' }}>
                            <Typography variant="h6" gutterBottom>📈 Porównanie ilości produktów wg lat</Typography>
                            <Box sx={{ minWidth: { xs: '800px', md: '100%' } }}>
                                <StylishTable
                                    columns={comparisonColumns}
                                    data={comparisonTableData}
                                />
                            </Box>
                        </Box>
                    )}
                </>
            ) : (
                !loading && !error && selectedClient && (
                    <Typography textAlign="center" color="text.secondary" sx={{ mt: 8 }}>
                        Wybierz klienta aby zobaczyć porównanie danych faktur i zamówień
                    </Typography>
                )
            )}
        </div>
    );
};

export default IntegratedDataPage;