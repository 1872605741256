import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
    CircularProgress, Typography, Box
} from '@mui/material';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import StylishTable from './StylishTable';
import ClientSummary from './ClientSummary';
import PageHeader from './PageHeader';
import { Person, ArrowBack, Download } from '@mui/icons-material';
import ActionButton from './ActionButton';

// Helper function for parsing discount values
function parseDiscount(value) {
    if (!value) return 0;
    // Always handle as string first to ensure consistent treatment
    const normalized = String(value).trim().replace(',', '.');
    const parsed = parseFloat(normalized);
    return isNaN(parsed) ? 0 : parsed;
}

const ClientProfilePage = () => {
    const { username, clientId } = useParams();
    const navigate = useNavigate();
    const [clientHistory, setClientHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [summary, setSummary] = useState(null);

    useEffect(() => {
        const fetchClientHistory = async () => {
            try {
                const response = await axios.get(`/api/client-history/${username}/${clientId}`);
                const purchases = response.data.purchases;

                // Sumowanie ilości i wartości dla każdej faktury
                const aggregatedData = {};
                purchases.forEach(entry => {
                    const invoiceNumber = entry["Dokument Numer"];
                    if (!invoiceNumber) return; // Pomijamy puste wartości

                    if (!aggregatedData[invoiceNumber]) {
                        aggregatedData[invoiceNumber] = {
                            date: entry["Data Wystawienia Dzień"],
                            quantity: 0,
                            value: 0.0,  
                            discount: 0,
                            discountCount: 0
                        };
                    }

                    // Konwersja wartości liczbowych
                    const quantity = parseInt(entry["Sprzedaż Ilość"]) || 0;
                    const value = parseFloat(String(entry["Sprzedaż Wartość"] || "0").replace(",", ".")) || 0.0;
                    // Konsekwentne użycie pomocniczej funkcji do prawidłowego parsowania rabatu
                    const discount = parseDiscount(entry["Sprzedaż Rabat %"]);

                    // 🛠️ Debugowanie wartości
                    console.log(`📜 Faktura: ${invoiceNumber} | Ilość: ${quantity} | Wartość: ${value} | Rabat: ${discount}`);

                    // Sumowanie wartości
                    aggregatedData[invoiceNumber].quantity += quantity;
                    aggregatedData[invoiceNumber].value += value;
                    if (entry["Sprzedaż Rabat %"]) {
                        aggregatedData[invoiceNumber].discount += discount;
                        aggregatedData[invoiceNumber].discountCount += 1;
                    }
                });

                // Konwersja do tablicy i sortowanie po dacie (od najnowszej do najstarszej)
                const sortedPurchases = Object.entries(aggregatedData)
                    .map(([invoice, data]) => ({
                        invoice,
                        date: data.date,
                        quantity: data.quantity,
                        value: parseFloat(data.value.toFixed(2)), // Dokładne zaokrąglenie
                        discount: data.discountCount > 0 
                            ? (data.discount / data.discountCount).toFixed(2) 
                            : "0.00"
                    }))
                    .sort((a, b) => new Date(b.date) - new Date(a.date)); // Odwrócone sortowanie

                // **Sumowanie wszystkich wartości** 
                const totalValue = sortedPurchases.reduce((acc, cur) => acc + cur.value, 0);
                const formattedTotalValue = totalValue
                    .toLocaleString('pl-PL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                console.log(`✅ Łączna wartość zakupów: ${formattedTotalValue} zł`);

                setClientHistory(sortedPurchases);
                setSummary({
                    totalValue: formattedTotalValue, // Separator tysięcy
                    averageDiscount: sortedPurchases.length > 0 
                        ? (sortedPurchases.reduce((acc, cur) => acc + parseFloat(cur.discount), 0) / sortedPurchases.length).toFixed(2) 
                        : "0.00",
                    topBrands: response.data.topBrands,
                });
            } catch (error) {
                console.error('❌ Błąd pobierania historii klienta:', error);
                setError('Nie udało się pobrać danych klienta.');
            } finally {
                setLoading(false);
            }
        };
        fetchClientHistory();
    }, [username, clientId]);

    return (
        <div style={{ padding: '20px', maxWidth: '1200px', margin: 'auto' }}>
            <PageHeader
                title={`Profil klienta: ${decodeURIComponent(clientId)}`}
                subtitle="Szczegółowa historia zakupów i analiza zachowań klienta"
                icon={<Person />}
            />

            {loading && <CircularProgress />}
            {error && <Typography color="error">{error}</Typography>}

            {summary && (
                <div style={{ marginTop: '20px' }}>
                    <Typography variant="h6" gutterBottom>📊 Podsumowanie</Typography>
                    <ClientSummary summary={summary} />
                    <Typography sx={{ mt: 2 }}>
                        <strong>Najczęściej kupowane marki:</strong> {summary.topBrands.join(', ')}
                    </Typography>
                </div>
            )}

            {clientHistory.length > 0 && (
                <div style={{ marginTop: '20px' }}>
                    <Typography variant="h6">📜 Historia zakupów</Typography>
                    <StylishTable
                        columns={[
                            { id: 'date', label: 'Data' },
                            { id: 'invoice', label: 'Numer Faktury', format: (value) => (
                                <Link to={`/invoice/${username}/${clientId}/${value.replace(/\//g, "-")}`} 
                                    style={{ textDecoration: 'none', color: '#1976d2', fontWeight: 'bold' }}>
                                    {value}
                                </Link>
                            )},
                            { id: 'quantity', label: 'Ilość', align: 'right' },
                            { id: 'value', label: 'Wartość (zł)', align: 'right', format: (value) => 
                                value.toLocaleString('pl-PL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            },
                            { id: 'discount', label: 'Rabat (%)', align: 'right', format: (value) => `${value}%` },
                        ]}
                        data={clientHistory}
                    />
                </div>
            )}

            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mt: 4 }}>
                <ActionButton 
                    icon={<ArrowBack />} 
                    label="Wróć do listy klientów" 
                    onClick={() => navigate('/client-data')} 
                />
                
                <ActionButton 
                    icon={<Download />} 
                    label="Eksportuj dane" 
                    variant="outlined" 
                    color="success"
                    onClick={() => {
                        // Możesz tutaj dodać funkcję eksportu danych
                        alert('Funkcja eksportu zostanie dodana wkrótce');
                    }} 
                />
            </Box>
        </div>
    );
};

export default ClientProfilePage;