import React, { useEffect, useState } from 'react';
import { Paper, Box } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FormPage from './FormPage';
import HistoryPage from './HistoryPage';
import Login from './Login';
import { ThemeProvider, CssBaseline, useMediaQuery } from '@mui/material';
import createAppTheme from './theme';
import NavigationMenu from './NavigationMenu';
import ClientDataPage from './ClientDataPage';
import RecentLoginsPage from './RecentLoginsPage';
import ClientProfilePage from './ClientProfilePage';
import InvoicePage from './InvoicePage';
import OrderDataPage from './OrderDataPage';
import OrderProfilePage from './OrderProfilePage';
import OrderPage from './OrderPage';
import ClientOrderProfile from './ClientOrderProfile';
import IntegratedDataPage from './IntegratedDataPage';
import IntegratedProfilePage from './IntegratedProfilePage';
import IntegratedDocumentPage from './IntegratedDocumentPage';
import ROPendingPage from './ROPendingPage';
import ROPendingDetailsPage from './ROPendingDetailsPage';
import ROAssignmentPage from './ROAssignmentPage';
import '@mui/icons-material/ReceiptOutlined';
import '@mui/icons-material/HistoryOutlined';
import '@mui/icons-material/BarChartOutlined'; 
import '@mui/icons-material/ShoppingCartOutlined';
import '@mui/icons-material/LogoutOutlined';
import '@mui/icons-material/Brightness4';
import '@mui/icons-material/Brightness7';
import '@mui/icons-material/Menu';
import '@mui/icons-material/ChevronLeft';
import '@mui/icons-material/CompareArrows';


function App() {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  const isMobile = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  return (
    <ThemeProvider theme={createAppTheme(darkMode ? 'dark' : 'light')}>
      <CssBaseline />
      <Router>
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            backgroundColor: (theme) => theme.palette.background.default,
            color: (theme) => theme.palette.text.primary,
          }}
        >
          {user && (
            <NavigationMenu
              darkMode={darkMode}
              setDarkMode={setDarkMode}
              onLogout={() => setUser(null)}
              userName={user.email.split('@')[0]}
            />
          )}

          <Box
            sx={{
              flex: 1,
              padding: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Paper
              elevation={3}
              sx={{
                width: '100%',
                maxWidth: '1200px',
                padding: '20px',
                backgroundColor: (theme) => theme.palette.background.paper,
                boxShadow: (theme) => theme.shadows[3],
              }}
            >
              {!user ? (
                <Login onLoginSuccess={(user) => setUser(user)} />
              ) : (
                <Routes>
                  <Route path="/form" element={<FormPage />} />
                  <Route path="/history" element={<HistoryPage />} />
                  <Route path="/client-data" element={<ClientDataPage />} />
                  <Route path="*" element={<FormPage />} />
                  <Route path="/recent-logins" element={<RecentLoginsPage />} />
                  <Route path="/client-profile/:username/:clientId" element={<ClientProfilePage />} />
                  <Route path="/invoice/:username/:clientId/:invoiceNumber" element={<InvoicePage />} />
                  <Route path="/order-data" element={<OrderDataPage />} />
                  <Route path="/order/:username/:clientId/:orderNumber" element={<OrderPage />} />
                  <Route path="/order-profile/:username/:clientId" element={<OrderProfilePage />} />
                  <Route path="/client-order-profile/:username/:clientId" element={<ClientOrderProfile />} />
                  {/* Nowe zintegrowane ścieżki */}
                  <Route path="/integrated-data" element={<IntegratedDataPage />} />
                  <Route path="/integrated-profile/:username/:clientId" element={<IntegratedProfilePage />} />
                  <Route path="/integrated-document/:username/:clientId/:documentNumber" element={<IntegratedDocumentPage />} />
                  <Route path="/ro-pending" element={<ROPendingPage />} />
                  <Route path="/ro-pending-details/:orderNumber" element={<ROPendingDetailsPage />} />
                  <Route path="/ro-przyporzadkowanie" element={<ROAssignmentPage />} />
                </Routes>
              )}
            </Paper>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;